import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import { TextField } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import DateFnsUtils from '@date-io/date-fns';
import { pl } from "date-fns/locale";

import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 450,
      backgroundColor: theme.palette.background.paper,
    },
  }));

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

function getDates(selected) {
    let d, a;

    switch (selected) {
        case 0: //DZISIAJ
            d = new Date();
            d.setUTCHours(0,0,0,0);
            return [d.toISOString(),d.toISOString() ];

        case 1: // WCZORAJ
            d = new Date();
            d.setUTCHours(0,0,0,0);
            d.setDate(d.getDate()-2);
            return [d.toISOString(),d.toISOString() ];

        case 2: // bieżący miesiąc
            d = new Date();
            d.setUTCHours(0,0,0,0);
            d.setDate(1);
            a = new Date(d.getTime());        
            a.setMonth(a.getMonth()+1);
            a.setDate(0);
            return [d.toISOString(),a.toISOString() ];

        case 3: // poprzedni miesiąc
            d = new Date();
            d.setUTCHours(0,0,0,0);
            d.setMonth(d.getMonth()-1);
            d.setDate(1);
            a = new Date(d.getTime());        
            a.setMonth(a.getMonth()+1);
            a.setDate(0);
            return [d.toISOString(),a.toISOString() ];

        case 4: // bieżący rok
            d = new Date();
            d.setMonth(0);
            d.setDate(1);
            d.setUTCHours(0,0,0,0);
            a = new Date(d.getTime());        
            a.setMonth(12);
            a.setDate(0);
            return [d.toISOString(),a.toISOString() ];
        case 5: // poprzedni rok
            d = new Date();
            d.setFullYear(d.getFullYear()-1);

            d.setMonth(0);
            d.setDate(1);
            d.setUTCHours(0,0,0,0);
            a = new Date(d.getTime());  
            a.setMonth(12);
            a.setDate(0);
            return [d.toISOString(),a.toISOString() ];

        default: break;
    }
}

function ResultDateDialog(props) {
    const { onClose, selectedValue, open } = props;
    const classes = useStyles();
    const [text, setText] = useState("");

	const [dateFrom, setDateFrom] = useState(null);
	const [dateTo, setDateTo] = useState(null);

	const handleDateChange = (newDate)=> {
		setDateFrom(newDate);

		if (dateTo) {
			if (newDate.getTime() > dateTo.getTime()){
				setDateTo(new Date(newDate));
			}
		}

	};

	const handleDateChange2 = (newDate)=> {
		setDateTo(newDate);

		if (dateFrom) {
			if (newDate.getTime() < dateFrom.getTime()){
				setDateFrom(new Date(newDate));
			}
		}
	};

	let fromTxt = "";
	let toTxt = "";

	try {
		if (dateFrom) {
			fromTxt = dateFrom.toISOString().split("T")[0];
		}
	} catch (err) { }

	try {
		if (dateTo) {
			toTxt = dateTo.toISOString().split("T")[0];
		}
	} catch (err) { }

	let dateTxt = "";

	if (fromTxt !== '' && toTxt === '') {
		dateTxt = 'od ' + fromTxt;
	} else if (fromTxt === '' && toTxt !== '') {
		dateTxt = 'do ' + toTxt;
	} else if (fromTxt !== '' && toTxt !== '') {
		dateTxt = `${fromTxt} - ${toTxt}`;
	}

  
    const handleClose = () => {
      onClose(selectedValue);
    };
  
    const handleListItemClick = (value) => {
      onClose(value);
    };
  
    return (
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">Wybierz datę rozpoczęcia działalności</DialogTitle>
        

        <div className={classes.root}>
      <List component="nav" aria-label="main mailbox folders">
      <Divider />
        <ListItem button onClick={(e)=>{ onClose("bez ograniczeń"); } }>
          <ListItemText primary="bez ograniczeń" />
        </ListItem>
        {/* <Divider />
        <ListItem button onClick={(e)=> {onClose(getDates(0)); }}>
          <ListItemText primary="dzisiaj" />
        </ListItem> */}
        <Divider />
        <ListItem button onClick={(e)=> {onClose(getDates(1)); }}>
          <ListItemText primary="wczoraj" />
        </ListItem>
        <Divider />
        <ListItem button onClick={(e)=> {onClose(getDates(2)); }}>
          <ListItemText primary="bieżący miesiąc" />
        </ListItem>
        <Divider />
        <ListItem button onClick={(e)=> {onClose(getDates(3)); }}>
          <ListItemText primary="poprzedni miesiąc" />
        </ListItem>
        <Divider />
        <ListItem button onClick={(e)=> {onClose(getDates(4)); }}>
          <ListItemText primary="bieżący rok" />
        </ListItem>
        <Divider />
        <ListItem button onClick={(e)=> {onClose(getDates(5)); }}>
          <ListItemText primary="poprzedni rok" />
        </ListItem>
        <Divider />
 
      </List>

	  <>
			<MuiPickersUtilsProvider locale={pl} utils={DateFnsUtils}>
				 <p style={{marginLeft: '10px', marginBottom: '0px', fontWeight: 'bold'}}> Inny zakres dat: </p>
			<div style={{marginLeft: '5px', textAlign: 'center'}}>

		<div >
			<KeyboardDatePicker
			style={{marginTop: '0px'}}
				helperText={''}
				disableToolbar
				variant="inline"
				format="yyyy/MM/dd" 
				margin="normal"
				id="date-picker-inline"
				label="data od"
				autoOk={true}
				value={dateFrom}
				onChange={handleDateChange}
				KeyboardButtonProps={{
					'aria-label': 'change date',
				}}
			/>
		</div>
		<div>

			<KeyboardDatePicker
				helperText={''}
				disableToolbar
				variant="inline"
				format="yyyy/MM/dd" 
				margin="normal"
				id="date-picker-inline"
				label="data do"
				autoOk={true}
				value={dateTo}
				onChange={handleDateChange2}
				KeyboardButtonProps={{
					'aria-label': 'change date',
				}}
			/>

			


		</div>

		<Button 
			 onClick={(e)=> {onClose(dateTxt); }}
			style={{marginBottom: '10px'}} variant="contained" color="secondary" >OK</Button>

		
			</div>

		</MuiPickersUtilsProvider>

    </>

      
    	</div>
      </Dialog>
    );
  }

  // ResultDateDialog.propTypes = {
  //   onClose: PropTypes.func.isRequired,
  //   open: PropTypes.bool.isRequired,
  //   selectedValue: PropTypes.string.isRequired,
  // };

  export default ResultDateDialog;