import React from 'react';

class Paginator extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }

        this.pageClick = this.pageClick.bind(this);
    }

    pageClick(page) {
        
        let nextPage = Number(this.props.currentPage);

        if (page === 'next') {
            nextPage++;
        } else if (page === 'prev') {
            nextPage--;
        } else {
            nextPage = page;
        }

        if (nextPage < 1) nextPage = 1;
        if (nextPage > this.props.pageCount) nextPage = this.props.pageCount;

        if ( Number(this.props.currentPage) === nextPage) return;

        if (this.props.onPageSelect) {
            this.props.onPageSelect(nextPage);
        }
    }

    render() {

        let pageCount = Number(this.props.pageCount);

        if (pageCount === 1) return (<></>);
        let currentPage = Number(this.props.currentPage);

        if (currentPage > pageCount) currentPage = pageCount;

        let fields = [];
        let fieldStyle = { minWidth: '80px', borderRadius: '3px', marginBottom: '10px' };
        let fieldStyleSelected = { fontWeight: 'bold', minWidth: '80px', borderRadius: '3px', color: 'black', marginBottom: '10px', backgroundColor: '#FFD662' };

        //#7f0b8e
        let fieldStyle2 = { width: '80px', borderRadius: '3px', marginBottom: '10px', backgroundColor: '#451966', color: 'white' };

        let showDots = false;
        let btnClasses = "btn btn-primary btn-block text-uppercase";

        
        if (pageCount>5) {
            fields.push(
                <a key='a'><button onClick={ (evt) => {this.pageClick('prev')} }  style={fieldStyle } type="submit" className={btnClasses}>⇽</button></a>
            );
            fields.push(<span key='b'>&nbsp;&nbsp;</span>);
        }

        let offsetA = 3;
        let offsetB = 3;
        let offset2 = 1;

        if (currentPage<=3 || currentPage >= pageCount-3) {
            offsetA=offsetB=5;
        }

        if (currentPage == 4) {
            offsetA = 7;  
        }

        if (currentPage == pageCount-3) {
            offsetA = 4;  
        }

        let przerwa = 0;

        let pre ='';
        if (this.props.prelink) {
            pre = this.props.prelink;
        }

        for (let i=1; i<=pageCount; i++) {

            let pageNum = '' + (pre+i);
            if (i == 1) pageNum = pre;

            if (pageNum.charAt(pageNum.length-1) == '/') {
                pageNum = pageNum.substr(0, pageNum.length-1);
            }

            let styles = i===currentPage?fieldStyleSelected:fieldStyle;

            if ((i<offsetA || i-1>pageCount-offsetB) || (i >= currentPage-offset2 && i <= currentPage+offset2) || pageCount<=10 ) {

                if (przerwa) {
                    //console.log('przerwa = ' + przerwa);

                    if (przerwa === 1) {
                        fields.push(
                            <a href={pageNum} key={i}><button onClick={ (evt) => {this.pageClick(i)} } style={styles} type="submit" className={btnClasses}>{i-1}</button></a>
                        );
                        fields.push(<span key={i*2+1}>&nbsp;&nbsp;</span>);
                    } else {
                        showDots = false;
                        fields.push(
                            <button key={i} style={fieldStyle2} type="submit" className="btn">...</button>
                        );
                        fields.push(<span key={i*2+1}>&nbsp;&nbsp;</span>);
                    }

                    przerwa = 0;
                }

                fields.push(
                    <a onClick={(e)=>{e.preventDefault()}} href={pageNum} key={i+'end'}><button onClick={ (evt) => {this.pageClick(i)} } style={styles} type="submit" className={btnClasses}>{i}</button></a>
                );
                fields.push(<span key={i+'end2'}>&nbsp;&nbsp;</span>);

                showDots = true;
            } else if (showDots) { 
                przerwa++;
            }
        }

        if (pageCount>5) {
            fields.push(
                <a key='end3'><button onClick={ (evt) => {this.pageClick('next')} }  style={fieldStyle } type="submit" className={btnClasses}>⇾</button></a>
            );
            //fields.push(<span key='end4'>&nbsp;&nbsp;</span>);
        }
        
        return (

            <div className="container">
                <div className="col-md-12">
                    <br />

                    <div className="row" style={{justifyContent: 'center'}}>
                        {fields}
                    </div>
                </div>
            </div>
        );
    }
}

export default Paginator;