import {Helmet} from "react-helmet";

function ThankYou() {
    return (<>
            <Helmet>
                <title>Dziękujemy za zakup bazy - BazaMaili</title>
                <meta name="description" content="Dziękujemy za zakup bazy." />
            </Helmet>
            
                <div className="container">
                    
                     <br/><br/>   
                        <h3><b><font color="#00539C">Dziękujemy</font></b></h3>
                    
                    <div className="row">
					<div className="col-md-12">					
							
					<br/>	

			Dziękujemy za zakup. Bazę otrzymasz w ciągu 24 godzin na adres e-mail podany podczas dokonywania płatności.					
					
                    </div>  
                    </div>
                </div>
            
       
    </>);
}

export default ThankYou;
