import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import BazaMailiAPI from '../services/BazaMailiAPI';
import ArticleTypeOne from './ArticleTypeOne';
import ArticleTypeTwo from './ArticleTypeTwo';

let bazaMailiApi = BazaMailiAPI.getInstance();


function Identify(props) {
    let history = useHistory();
    let params = useParams();
    let [ident, setIdent] = useState(null);

    // console.log(params);
    let {text} = params;

    useEffect(()=> {
        let fetchData = async () => {
            console.log(`identifying ${text}`);
            let ident = await bazaMailiApi.identify(text);
            if (ident.result === "404") history.push('/404');
            console.log(ident);
            
            setIdent(ident);


        };

        fetchData();
    }, [text]);

    function makeOrder(criteria) {
        // console.log(criteria);

        if (props.onUpdate) {
            props.onUpdate("home", {searchCriteria: criteria, action: "generateRaport"});
            history.push('/raport');
        }
    }

    return (<>

        {ident  && <>
        { ident.article !== undefined && <>
            <ArticleTypeOne article={ident.article} />
        </>}

        { ident.article2 !== undefined && <>
            <ArticleTypeTwo makeOrder={makeOrder} article={ident.article2} />
        </>}

        </>}
    
    </>);
}

export default Identify;