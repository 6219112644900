import {Helmet} from "react-helmet";

function Error404() {
    return (<>
            <Helmet>
                <title>Błąd 404 - BazaMaili</title>
                <meta name="description" content="Błąd 404. Podana strona nie istnieje. Wróć na stronę główną i spróbuj ponownie." />
            </Helmet>
            
                <div className="container">
                    
                     <br/><br/>   
                        <h3><b><font color="#00539C">Błąd 404</font></b></h3>
                    
                    <div className="row">
					<div className="col-md-12">					
							
					<br/>	
			Wybrany adres URL nie istnieje. Wróć na stronę główną i spróbuj ponownie.
					
					
                    </div>  
                    </div>
                </div>
            
       
    </>);
}

export default Error404;