import { useEffect, useState } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import BazaMailiAPI from '../services/BazaMailiAPI';
let bazaMailiApi = BazaMailiAPI.getInstance();

const PrettoSlider = withStyles({
    root: {
        color: '#52af77',
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);

function Raport(props) {
    let history = useHistory();
    let [msg, setMsg] = useState("");
    let [state, setState] = useState("searching");
    let [ws, setWs] = useState(null);
    let [basicInformation, setBasicInformation] = useState(0);
    let [searchResult, setSearchResult] = useState(null);
    let [contactsCount, setContactsCount] = useState({ a: 0, b: 0, c: 0, d: 0 });
    let [searchProgress, setSearchProgress] = useState(null);
    let [isUserLogged, setIsLogged] = useState(false);
    const onMessage = (event) => {

        let msgParsed = JSON.parse(event.data);
        setMsg(JSON.stringify(msgParsed, null, 2));

        setSearchProgress(msgParsed.msg);

        if (msgParsed.type === 'done') {
            setSearchResult(msgParsed.msg);

            window.iloscWynikow = msgParsed.msg;
            window.lastSearchCriteria = props.data;

            setState("search_done");
        }
    }

    useEffect(() => {

        const fetchData2 = async () => {
            let isUserLogged = await bazaMailiApi.checkIfUserIsLogged();
            console.log(`isUserLogged: ${isUserLogged}`);

            setIsLogged(isUserLogged);
        }

        fetchData2();


        window.iloscWynikow = null;

        return () => {
            console.log('disconnecting websocket!');

            if (window.ws !== undefined)
                window.ws.close();
        };

    }, []);

    useEffect(() => {
        if (props.data === undefined) return;

        //console.log(props);
        console.log("will open websocket connection nowa");

        let ws;

        let proto = window.location.protocol === 'http:' ? 'ws://' : 'wss://';

        ws = new WebSocket(proto + window.location.host + "/api/ws", "protocolOne");
        setWs(ws);

        window.ws = ws;
        ws.onmessage = onMessage;
        ws.onopen = (event) => {
            ws.send(JSON.stringify(props.data));
        }
    }, [props.data]);

    let formRef;

    const makeOrder = async () => {
        let body = {
            counts: contactsCount,
            searchCriteria: props.data,
            price: cena
        }
        // console.log(body);

        let result = await bazaMailiApi.makeOrder(body);
        console.log(result);

        if (result.status === 'ok') {
            //console.log(result.order._id);

            // https://ssl.dotpay.pl/t2/?id=123456&amount=123.00&currency=PLN&description=Test

            let url = result.dotpayUrl;

            //     (
            //         [id] => 123456
            //         [amount] => 98.53
            //         [currency] => PLN
            //         [description] => Order123
            //         [url] => https://www.example.com/thanks_page.php
            //         [type] => 0
            //    )



            url += `?id=${result.shopId}&control=${result.order._id}&api_version=next&amount=${result.order.price.toFixed(2)}&currency=PLN&description=${encodeURI(result.description)}&url=${result.url}&type=0&chk=${result.rchk}`;
            // console.log(url);

            // window.location.href = url;
            // console.log(formRef);

            document.getElementById("nazwa_uslugi").value =result.description;
            document.getElementById("id_zamowienia").value =result.order._id;

            formRef.submit();
                //         formRef.dispatchEvent(
                // new Event("submit", { bubbles: true, cancelable: true })
            //)

        } else alert('makeOrder error');
    }

    let addInfo = "";
    let cena = contactsCount.a * 0.01 + (contactsCount.b + contactsCount.c) * 0.03 + contactsCount.d * 0.05;

    if (searchProgress) {
        addInfo = <>, dotychczas odnaleziono <span style={{ fontWeight: 'bold', color: '#00539C' }}>{searchProgress.aktywny}</span> pasujących rekordów.</>;
    }

    if (searchProgress && state === 'search_done') {
        addInfo = <>, odnaleziono <span style={{ fontWeight: 'bold', color: '#00539C' }}>{searchProgress.aktywny}</span> pasujących rekordów.</>;
    }

    return (
        <>

            {state === 'searching' && <>
                <div style={{ padding: '20px', textAlign: 'center' }}>
                    <p style={{ fontSize: '1.2em' }}>Trwa przeszukiwanie bazy danych{addInfo} </p><CircularProgress />
                </div>

            </>}

            {/* <pre> 
            {msg}
        </pre>
 */}



            {state === 'search_done' &&
                <>
                    <div style={{ padding: '20px', textAlign: 'center' }}>
                        <p style={{ fontSize: '1.3em' }}>Wyszukiwanie zakończone{addInfo}</p>
                    </div>
                    <div style={{ width: '80%', margin: 'auto', padding: '20px' }}>



                        <Typography gutterBottom>Wpisy z adresem e-mail{contactsCount.b > 0 ? " (" + contactsCount.b + " x 0,03 PLN = " + (contactsCount.b * 0.03).toFixed(2).replace(".", ",") + " PLN)" : ""}</Typography>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs>
                                <Slider disabled={searchResult.contactEmail === 0}
                                    onChange={(e, val) => { setContactsCount({ ...contactsCount, b: val }); }}
                                    value={contactsCount.b} valueLabelDisplay="auto" max={searchResult.contactEmail} aria-label="pretto slider" defaultValue={0} />
                            </Grid>
                            <Grid item>
                                <p style={{ width: '100px', textAlign: 'center' }}>{contactsCount.b}/{searchResult.contactEmail} </p>
                            </Grid>
                        </Grid>

                        <Typography gutterBottom>Wpisy z numerem telefonu{contactsCount.c > 0 ? " (" + contactsCount.c + " x 0,03 PLN = " + (contactsCount.c * 0.03).toFixed(2).replace(".", ",") + " PLN)" : ""}</Typography>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs>
                                <Slider disabled={searchResult.phoneNumber === 0}
                                    onChange={(e, val) => { setContactsCount({ ...contactsCount, c: val }); }}
                                    value={contactsCount.c} valueLabelDisplay="auto" max={searchResult.phoneNumber} aria-label="pretto slider" defaultValue={0} />
                            </Grid>
                            <Grid item>
                                <p style={{ width: '100px', textAlign: 'center' }}>{contactsCount.c}/{searchResult.phoneNumber}</p>
                            </Grid>
                        </Grid>

                        <Typography gutterBottom>Wpisy z adresem e-mail oraz numerem telefonu{contactsCount.d > 0 ? " (" + contactsCount.d + " x 0,05 PLN = " + (contactsCount.d * 0.05).toFixed(2).replace(".", ",") + " PLN)" : ""}</Typography>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs>
                                <Slider disabled={searchResult.both === 0}
                                    onChange={(e, val) => { setContactsCount({ ...contactsCount, d: val }); }}
                                    value={contactsCount.d} valueLabelDisplay="auto" max={searchResult.both} aria-label="pretto slider" defaultValue={0} />
                            </Grid>
                            <Grid item>
                                <p style={{ width: '100px', textAlign: 'center' }}>{contactsCount.d}/{searchResult.both}</p>
                            </Grid>
                        </Grid>

                        <Typography gutterBottom>Wpisy bez danych kontaktowych{contactsCount.a > 0 ? " (" + contactsCount.a + " x 0,01 PLN = " + (contactsCount.a * 0.01).toFixed(2).replace(".", ",") + " PLN)" : ""}</Typography>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs>
                                <Slider disabled={searchResult.none === 0}
                                    onChange={(e, val) => { setContactsCount({ ...contactsCount, a: val }); }}
                                    value={contactsCount.a} valueLabelDisplay="auto" max={searchResult.none} aria-label="pretto slider" defaultValue={0} />
                            </Grid>
                            <Grid item>
                                <p style={{ width: '100px', textAlign: 'center' }}>{contactsCount.a}/{searchResult.none}</p>
                            </Grid>
                        </Grid>



                    </div>

                    <form ref={ref => formRef = ref} id="order" action="https://platnosc.hotpay.pl/" method="post">
                        <input required name="SEKRET" value="SE4yODNTbHVocEUvdnRwUWNnREdQMTAvVlMwSUpaVGxYa05jNVAzc2hEaz0" type="hidden" />
                        <input required name="KWOTA" value={cena} type="hidden" />
                        <input required id="nazwa_uslugi" name="NAZWA_USLUGI" value="BazaMaili zamówienie" type="hidden"/>
                        <input required name="ADRES_WWW" value="https://bazamaili.pl/thank_you" type="hidden" />
                        <input required id="id_zamowienia" name="ID_ZAMOWIENIA" value="XXX" type="hidden" />
                        {/* <input name="EMAIL" value="" type="hidden"/>
<input name="DANE_OSOBOWE" value="" type="hidden"/> */} 
                        
                    </form>

                    <div style={{ padding: '20px' }}>
                        <div style={{ textAlign: 'right', fontSize: '1.3em', marginTop: '20px' }}>
                        <span style={{color: 'red', fontSize: "0.7em"}}>{cena < 1 && <>(minimalna kwota zamówienia: 1 PLN) </>}</span>
                            <span>
                                
                            
                            
                            całkowity koszt: <b>{cena.toFixed(2).replace(".", ",")} PLN</b> 
                            
                            
                             </span>
                            <Button style={{marginLeft: '10px'}}
                                onClick={(e) => { makeOrder(); }}
                                disabled={cena < 1  } variant="contained" color="primary" size="large">Zamów bazę</Button>
                            {isUserLogged && <Button style={{ marginLeft: '5px' }} onClick={(e) => { history.push('/edit_article2'); }} variant="contained" color="secondary" size="large">Utwórz artykuł typu drugiego</Button>}
                        </div>
                    </div>
                </>
            }

        </>
    );
}

export default Raport;
