import {Helmet} from "react-helmet";
import {useHistory, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import FileUpload from "./FileUpload";
import {Parser} from "react-tiny-bbcode";

import BazaMailiAPI from '../services/BazaMailiAPI';
let bazaMailiApi = BazaMailiAPI.getInstance();

function EditArticleTypeOne() {
    let history = useHistory();
    let params = useParams();
    let [article, setArticle] = useState({
        title: '', content: ''
    });
    let {text} =  params;

    useEffect(()=>{
        if (text !== undefined) {
            console.log("text query search = " + text);

            let fetchData = async () => {
                let article = await bazaMailiApi.getArticle(text);
                setArticle(article);
            };

            fetchData();
        }
    }, [text]);

    function uploadDone(data) {
        setArticle({...article, content: article.content += `[img]${data.data.path}[/img]\n` });
    }

    async function saveArticle() {

        if (article.title.trim() === '' ||  article.content.trim() === '') {
            alert('Wszystkie pola są wymagane!');
            return;
        }

        let res = await bazaMailiApi.saveArticle(article);
        alert('ok!');
        history.push('/edit_article/'+res.urlName);
    }

    return (<>
            <Helmet>
                <title>Edycja artykułu - BazaMaili</title>
                {/* <meta name="description" content={props.article.content.substr(0, 100)} /> */}
            </Helmet>
            
            <div className="container" style={{paddingTop: '20px'}}>
                    
            
                   <div className="row">
                        <div className="col-lg-6">					
                            
                            <p> <button onClick={(evt)=> { history.push('/panel');} } >powrót do panelu</button> &nbsp; <button onClick={(evt)=>{ saveArticle();} } >zapisz</button>  </p>
                    
                            Tytuł:
                            <input type="text" style={{width: '100%'}}
                                onChange={ (evt)=> { setArticle({...article, title: evt.target.value}); } }
                                value={article.title} />
                            Treść:
                            <div style={{width: '100%', height: '500px'}}>
                                <textarea style={{width: '100%', height: '500px'}}
                                    value = {article.content}
                                onChange={ (evt)=> {setArticle({...article, content: evt.target.value}); } }
                            
                                />
                            </div>

                                <br/>
                            <p> <button onClick={(evt)=> {history.push('/panel');} } >powrót do panelu</button> &nbsp; <button onClick={(evt)=>{ saveArticle();} } >zapisz</button>  </p>

                                <FileUpload uploadDone={uploadDone}/>

                        </div>

                   
                        <div className="col-lg-6">	

                           
                            <h3><b><font color="#00539C">{article.title}</font></b></h3>
                
                    <Parser bbcode={article.content} />                  

                            

                
                        </div> 
                    </div>
                   
            </div>
    </>);
}

export default EditArticleTypeOne;