import {Helmet} from "react-helmet";
import {useState, useEffect} from 'react';
import BazaMailiAPI from '../services/BazaMailiAPI';
import FakeA from './FakeA';
import {Parser} from "react-tiny-bbcode";

let bazaMailiApi = BazaMailiAPI.getInstance();

function ArticleTypeOne(props) {
    const [randomArticles, setRandomArticles] = useState([]);

    useEffect(()=> {

        bazaMailiApi.getRandomArticles().then( (articles)=> {
            setRandomArticles(articles.splice(0, 10));
        });

    }, []);

    return (<>
            <Helmet>
                <title>{props.article.title} - BazaMaili</title>
                <meta name="description" content={props.article.content.substr(0, 140)+'...'} />
            </Helmet>
            
            <div className="container">
                    
                    <br/><br/>   
                       <h3><b><font color="#00539C">{props.article.title}</font></b></h3>
                   <br/>
                   <div className="row">
                   <div className="col-md-9">					
                    <Parser bbcode={props.article.content} />                   
                   </div> 
                   <div className="col-md-3">					
                       <h5><b><font color="#00539C">Polecane Artykuły</font></b></h5>
                   <br/>	

                   {randomArticles.map( (elem, idx) => {
                        return <>
                            <font color={idx%2?"#00539C":"#FFD662"}>►</font> <FakeA href={'/'+elem.urlName}>{elem.title}</FakeA><br/><br/>
                        </>
                   })}

                   </div> 
                   
                   </div>
               </div>
    </>);
}

export default ArticleTypeOne;