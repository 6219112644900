import React, { useRef, useState } from 'react';
import axios from 'axios';

function FileUpload(props) {
    const [file, setFile] = useState(''); // storing the uploaded file    // storing the recived file from backend
    const [data, getFile] = useState({ name: "", path: "" });    
    const [progress, setProgess] = useState(0); // progess bar

    const el = useRef(); // accesing input element
    const handleChange = (e) => {

    }

    const uploadFile = (e) => {
        setProgess(0)
        const file = e.target.files[0]; // accesing file
        console.log(file);
        setFile(file); // storing file

        const formData = new FormData();        
        formData.append('file', file); // appending file
        axios.post('/api/upload', formData, {
            onUploadProgress: (ProgressEvent) => {
                let progress = Math.round(
                ProgressEvent.loaded / ProgressEvent.total * 100) + '%';
                setProgess(progress);
            }
        }).then(res => {
            console.log(res);
            getFile({ name: res.data.name,
                     path: res.data.path
                   });

            props.uploadDone(res);
        }).catch(err => console.log(err))}

    return (
        <div>
            <div className="file-upload">
                <input type="file" ref={el} onChange={uploadFile} />
                <div className="progessBar" style={{ width: progress }}>
                   {progress!==0?progress:''}
                </div>
                {/* <button onClick={uploadFile} className="upbutton">Wyślij</button> */}
            <hr />
            {/* displaying received image*/}
            {data.path && <img src={data.path} style={{width: '200px'}} className="img-fluid" alt={data.name} />}
            </div>
        </div>
    );
}
export default FileUpload;