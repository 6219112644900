import {Helmet} from "react-helmet";
import {useState, useEffect} from 'react';
import BazaMailiAPI from '../services/BazaMailiAPI';
import FakeA from './FakeA';
let bazaMailiApi = BazaMailiAPI.getInstance();

function ArticleTypeTwo(props) {

    const [randomArticles2, setRandomArticles2] = useState([]);

    useEffect(()=> {

        bazaMailiApi.getRandomArticles2().then( (articles)=> {
            setRandomArticles2(articles);
        });

    }, []);

    let description = `Prezentowana ${props.article.title2} zawiera dane ${props.article.counts[0]} podmiotów gospodarczych. W powyższej tabeli prezentujemy liczbę uwzględnionych w bazie rekordów zawierających poszczególne informacje. Pamiętaj, że podczas zamawiania bazy sam decydujesz, czy chcesz kupić wyłącznie listę firm posiadających adresy mailowe lub dane teleadresowe, czy tylko podmioty bez danych kontaktowych.`;

    return (<>
            <Helmet>
                <title>{props.article.title} - BazaMaili</title>
                <meta name="description" content={description.substr(0, 140) + '...'} />
            </Helmet>
                    
                <div className="container">
                    
                     <br/><br/>   
                     <h3><b><font color="#00539C">{props.article.title}</font></b></h3>
                     <br/>
                    <div className="row">
					<div className="col-md-9">	      
                            
                        Zamów bazę z aktualnymi danymi polskich przedsiębiorstw i instytucji zarejestrowanych w CEIDG, REGON i KRS, których kod głównej działalności według Polskiej Klasyfikacji Działalności 2007 to {props.article.pkd}.
<br/><br/>
<div style={{overflowX:'auto'}}>
                            <table className="table table-bordered">
                                <thead style={{backgroundColor: "#FBFBFB"}}>
                                    <tr>
                                        <th><center><font color="#666666">Liczba rekordów</font></center></th>
										<th><center><font color="#666666">Z podstawowymi danymi</font></center></th>
                                        <th><center><font color="#666666">Z adresem email</font></center></th>
                                        <th><center><font color="#666666">Z numerem telefonu</font></center></th>
										<th><center><font color="#666666">Email + numer telefonu</font></center></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><center><b><font color="#00539C">{props.article.counts[0]}</font></b></center></td>
                                        <td><center><b><font color="#00539C">{props.article.counts[1]}</font></b></center></td>
                                        <td><center><b><font color="#00539C">{props.article.counts[2]}</font></b></center></td>
										<td><center><b><font color="#00539C">{props.article.counts[3]}</font></b></center></td>
                                        <td><center><b><font color="#00539C">{props.article.counts[4]}</font></b></center></td>
                                    </tr>
                                    
                                </tbody>
								<tbody>
                                    <tr>
                                        <td style={{backgroundColor: "#FBFBFB"}}><center><b><font color="#666666">Cena za wpis</font></b></center></td>
                                        <td><center><b><font color="#FFD662">0,01 zł</font></b></center></td>
                                        <td><center><b><font color="#FFD662">0,03 zł</font></b></center></td>
										<td><center><b><font color="#FFD662">0,03 zł</font></b></center></td>
                                        <td><center><b><font color="#FFD662">0,05 zł</font></b></center></td>
                                    </tr>
                                    
                                </tbody>
                            </table>
</div>
Prezentowana {props.article.title2} zawiera dane {props.article.counts[0]} podmiotów gospodarczych.
W powyższej tabeli prezentujemy liczbę uwzględnionych w bazie rekordów zawierających poszczególne informacje.
Pamiętaj, że podczas zamawiania bazy sam decydujesz, czy chcesz kupić wyłącznie listę firm posiadających adresy mailowe lub dane teleadresowe, czy tylko podmioty bez danych kontaktowych.	
<div className="about">
<div className="about-text">
<br/>
<center>
<a className="btn" onClick={(e)=>{ props.makeOrder( JSON.parse(props.article.searchCriteria) ) }} >ZAMÓW BAZĘ</a>
</center>
<br/>
</div>
</div>	
Jeżeli zdecydujesz się na zakup danych z tej bazy, kliknij przycisk "ZAMÓW BAZĘ", który uruchomi proces generowania danych. 
Poczekaj chwilę aż pokażą się wyniki, a następnie wybierz typ danych, jakie Cię interesują i potwierdź złożenie zamówienia.
Ostatnim etapem będzie dokonanie płatności online za pośrednictwem serwisu Dotpay. 
<br/><br/>
	<ul className="list-group">
         <li style={{backgroundColor: "#F7F7F7"}} className="list-group-item"><b>Źródło danych:</b> CEIDG, REGON, KRS, pozostałe</li>
         <li className="list-group-item"><b>Aktualność danych:</b> powyżej 95%</li>
		 <li style={{backgroundColor: "#F7F7F7"}} className="list-group-item"><b>Format plików:</b> XLSX</li>
		 <li className="list-group-item"><b>Formy płatności:</b> Dotpay, karta płatnicza, przelew bankowy</li>
		 <li style={{backgroundColor: "#F7F7F7"}} className="list-group-item"><b>Czas realizacji:</b> do 24 godzin</li>
    </ul>
<br/>
Oferowana baza zawiera dane firm, spółek i instytucji z całej Polski. Niektóre dane zaprezentowane w tabeli na górze strony	mogą być nieaktualne, gdyż prawdopodobnie od czasu opublikowania tego artykułu pojawiło się wiele nowych podmiotów.
							
<br/><br/>
<center>
<h4><b><font color="#00539C">Opis</font></b></h4>
</center>
{props.article.content}
<br/><br/>                               
					</div> 


					<div className="col-md-3">					
						<h5><b><font color="#00539C">Polecane Bazy</font></b></h5>
					<br/>	
					

                    {randomArticles2.map( (elem, idx) => {
                        return <>
                            <font color={idx%2?"#00539C":"#FFD662"}>►</font> <FakeA href={'/'+elem.urlName}>{elem.title}</FakeA><br/><br/>
                        </>
                   })}

                    </div> 					
                    </div>
                    </div>           

    </>);
}

export default ArticleTypeTwo;