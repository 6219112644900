import {
    Switch,
    Route,
} from "react-router-dom";

import { useState } from 'react';

import Regulamin from "./Regulamin";
import Kontakt from "./Kontakt";
import Prywatnosc from "./Prywatnosc";
import Onas from "./Onas";
import FAQ from "./FAQ";
import Cennik from "./Cennik";
import Home from "./Home";
import Katalog from "./Katalog";
import Oferta from "./Oferta";
import Raport from "./Raport";
import Identify from "./Identify";
import Error404 from "./Error404";
import EditArticleTypeOne from "./EditArticleTypeOne";
import EditArticleTypeTwo from "./EditArticleTypeTwo";
import Panel from "./Panel";
import ThankYou from "./ThankYou";

function PageContent() {

    const [pageData, setPageData ] = useState ({});

    const onUpdate = function (page, data) {
        // console.log(`PageContent got update from page ${page}`);
        // console.log("data: ");
        // console.log(data);

        if (data.action === 'generateRaport') {
            //pageData["raport"] = data.searchCriteria;

            console.log("setting page data..");
            setPageData({...pageData,  raport: data.searchCriteria} );

            console.log({raport: data.searchCriteria});
            console.log("page data set");
        }
    }


    return (<>
            <Switch>
                <Route path="/raport">
                    <Raport data={pageData["raport"]} />
                </Route>
                <Route path="/regulamin">
                    <Regulamin />
                </Route>
                <Route path="/thank_you">
                    <ThankYou />
                </Route>
                <Route path="/kontakt">
                    <Kontakt />
                </Route>
                <Route path="/prywatnosc">
                    <Prywatnosc />
                </Route>
                <Route path="/onas">
                    <Onas />
                </Route>

                <Route path="/faq">
                    <FAQ />
                </Route>
                <Route path="/cennik">
                    <Cennik />
                </Route>

                <Route path="/katalog/:page?">
                    <Katalog />
                </Route>

                <Route path="/oferta">
                    <Oferta />
                </Route>

                <Route path="/404">
                    <Error404 />
                </Route>

                <Route path="/panel">
                    <Panel />
                </Route>

                <Route path="/edit_article/:text">
                    <EditArticleTypeOne />
                </Route>

                <Route path="/edit_article">
                    <EditArticleTypeOne />
                </Route>

                <Route path="/edit_article2/:text">
                    <EditArticleTypeTwo />
                </Route>

                <Route path="/edit_article2">
                    <EditArticleTypeTwo />
                </Route>

                <Route path="/:text">
                    <Identify onUpdate={onUpdate} />
                </Route>
                
                <Route path="/">
                    <Home onUpdate={onUpdate} />
                </Route>

                
                {/*
                <Route path="/policy">
                    <Policy />
                </Route>

                <Route path="/dmca">
                    <Dmca />
                </Route>


                <Route path="/fullindex/:page">
                    <Fullindex />
                </Route>

                <Route path="/fullindex">
                    <Fullindex />
                </Route>

                <Route path="/contact">
                    <Contact />
                </Route>

                <Route path="/add">
                    <Add />
                </Route>


                <Route path="/search/:term">
                    <Search />
                </Route>

                <Route path="/result">
                    <Result />
                </Route>



                <Route path="/">
                    <Home />
                </Route> */}

              
            </Switch>
    </>);
}

export default PageContent;
