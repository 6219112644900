import {Helmet} from "react-helmet";

function Oferta() {

    return (<>
    <Helmet>
        <title>Oferta - BazaMaili</title>
        <meta name="description" content="Przestawiamy pełną ofertę serwisu BazaMaili, specyfikację dostarczanych baz, zakres świadczonych usług oraz zalety wyszukiwarki." />
   </Helmet>
             
   <div className="container">
                    
                    <br/><br/>   
                       <h3><b><font color="#00539C">Oferta</font></b></h3>
                   <br/>
                   <div className="row">
                   <div className="col-md-12">					
                               
Nasz serwis to najlepsze narzędzie dla wszystkich, którzy planują kampanię telemarketingową, e-mail marketingową, direct mailingową lub rozesłanie reklamy swojej marki drogą pocztową.			
Sprzedajemy aktualne bazy danych firm z całej Polski zawierające wszelkie dane kontaktowe oraz adresowe, które umożliwią Ci przeprowadzenie dowolnej kampanii marketingowej.
Posiadamy dane o indywidualnych przedsiębiorcach, spółkach, organizacjach i instytucjach publicznych. Dzięki sortowaniu i filtrowaniu danych umożliwiamy wygenerowanie bazy idealnie
dostosowanej do Twoich potrzeb. Dzięki nam zaoszczędzisz sporo pieniędzy, ponieważ nie zamawiasz u nas ogólnej bazy wszystkich firm czy osób decyzyjnych, lecz jedynie firmy z wybranej przez
Ciebie branży, miasta, województwa lub z określoną datą rozpoczęcia działalności. 
<img style={{float: 'right', maxWidth: '100%'}} src="img/bazy-danych-b2b.png"/>

<br/><br/>

<b>Zamawiając bazę otrzymujesz pełny pakiet danych o każdej firmie:</b><br/><br/>
<font color="#00539C">►</font> imię i nazwisko właściciela<br/>
<font color="#FFD662">►</font> NIP i REGON<br/>
<font color="#00539C">►</font> nazwa firmy<br/>
<font color="#FFD662">►</font> data rozpoczęcia działalności<br/>
<font color="#00539C">►</font> kody TERC, SIMC i ULIC<br/>
<font color="#FFD662">►</font> ulica i numer budynku<br/>
<font color="#00539C">►</font> gmina, dzielnica i miejscowość<br/>
<font color="#FFD662">►</font> kod pocztowy<br/>
<font color="#00539C">►</font> powiat i województwo<br/>
<font color="#FFD662">►</font> adres email<br/>
<font color="#00539C">►</font> numer telefonu<br/>
<font color="#FFD662">►</font> adres strony internetowej<br/>
<font color="#00539C">►</font> numer identyfikacyjny<br/>
<font color="#FFD662">►</font> ID wpisu
<br/><br/>
<div className="clearfix"> </div>
Umożliwiamy klientom zamawianie marketingowych baz danych z informacjami o wszystkich podmiotach gospodarczych działających w Polsce.
Jeżeli chcesz nawiązać kontakt z nowymi kontrahentami, prowadzisz badania marketingowe lub budujesz własną bazę danych, dzięki naszemu serwisowi w kilka minut pobierzesz informacje o interesujących Cię firmach lub innych instytucjach.
Cały zbiór danych dostępny w naszej ofercie jest codziennie aktualizowany, a co za tym idzie możemy zagwarantować, że odsetek rekordów zawierających błędne lub nieaktualne informacje w zamówionych bazach jest znikomy.
Dane udostępniane w naszych bazach mogą być przez Ciebie wykorzystywane w dowolnych celach nienaruszających obowiązującego prawa, z wyłączeniem ich dalszej odsprzedaży lub udostępnienia obcym podmiotom.
<br/><br/>
<b>W naszej bazie znajdują się wszystkie podmioty gospodarcze zarejestrowane w Polsce:</b><br/><br/>
<font color="#00539C">►</font> przedsiębiorcy indywidualni prowadzący działalność gospodarczą na podstawie wpisu do CEIDG<br/>
<font color="#FFD662">►</font> spółki cywilne prowadzące działalność na podstawie umowy zawartej zgodnie z Kodeksem cywilnym<br/>
<font color="#00539C">►</font> rolnicy indywidualni zarejestrowani w rejestrze REGON i figurujący w Wykazach Beneficjentów Wspólnej Polityki Rolnej<br/>
<font color="#FFD662">►</font> spółki handlowe i osobowe prowadzące działalność na podstawie przepisów Kodeksu spółek handlowych<br/>
<font color="#00539C">►</font> organizacje społeczne zarejestrowane w Krajowym Rejestrze Sądowym lub w innych rejestrach<br/>
<font color="#FFD662">►</font> instytucje publiczne figurujące w rejestrze REGON<br/>
<br/>

<img style={{float: 'left', maxWidth: '100%', marginRight: '15px'}} src="img/ceidg-krs-regon.png"/>

<br/>
Po wygenerowaniu bazy ostatnim etapem składania zamówienia jest dokonanie płatności online za pośrednictwem systemu hotpay. Gwarantuje on bezpieczeństwo i szybki transfer pieniędzy, a dzięki temu niemal natychmiast zajmiemy się realizacją Twojego zlecenia.
Maksymalny przewidywany czas oczekiwania na zakupioną bazy to 24 godziny, ale zazwyczaj przysyłamy plik już po kilku godzinach od chwili złożenia zamówienia.
Bazę otrzymasz bezpośrednio w wiadomości email, która zostanie wysłana na adres mailowy podany podczas transakcji w systemie hotpay. 
<br/><br/>
Rejestracja nie jest wymagana, a prosty i intuicyjny interfejs gwarantują wygodne, łatwe i bezpieczne korzystanie z naszego serwisu.
Codziennie uzupełniamy zasoby naszej bazy o dane nowych podmiotów zarejestrowanych w REGON, CEIDG i KRS w poprzednim dniu.
W odróżnieniu od innych dostawców marketingowych baz danych, nie ograniczamy liczby akcji marketingowych lub sprzedażowych, w których można wykorzystać zamówione u nas informacje.

<div className="clearfix"> </div>
<br/><br/>
<div className="row">
<div className="col-md-6">
<b>Sam wybierasz specyfikację bazy podczas zakupu na podstawie niżej wymienionych kryteriów:</b>
<br/><br/>
<font color="#00539C">►</font> branża na podstawie kodu pkd<br/>
<font color="#FFD662">►</font> miejscowość<br/>
<font color="#00539C">►</font> województwo<br/>	
<font color="#FFD662">►</font> data rozpoczęcia działalności<br/>
<font color="#00539C">►</font> wielkość bazy<br/>
</div>
<div className="col-md-6">
<b>Cena bazy jest zawsze ustalana indywidualnie w chwili zakupu na podstawie liczby wpisów oraz rodzaju danych. Poniżej znajdują się ceny za pojedynczy wpis:</b>
<br/><br/>
<font color="#00539C">►</font> cena wpisu z podstawowymi danymi: 0,01 zł<br/>
<font color="#FFD662">►</font> cena wpisu z adresem email: 0,03 zł<br/>
<font color="#00539C">►</font> cena wpisu z numerem telefonu: 0,03 zł<br/>	
<font color="#FFD662">►</font> cena wpisu z z obiema formami kontaktu: 0,05 zł<br/>
</div>
</div>
<div className="clearfix"> </div>
<br/>
Zamawiając naszą bazę możesz być pewien, że nie będzie to kolejna kupiona w sieci lista kontaktów, po której odnotujesz słaby odzew i skromne zainteresowanie reklamą, którą rozesłałeś. 
U nas zakupisz szczegółową i obszerną bazę z uprzednio wybranej przez Ciebie kategorii, co sprawi że zdecydowanie większy procent Twoich potencjalnych klientów będzie realnie zainteresowany kupnem Twojego produktu i osiągniesz o wiele lepsze wyniki sprzedaży. 
Od kilku lat z powodzeniem tworzymy bazy danych firm, a każdy pozyskany kontakt jest przez nas wielokrotnie weryfikowany. 
Lista firm jest aktualizowana raz dziennie w oparciu o raporty z serwisów REGON, KRS i CEIDG, a dzięki temu gwarantujemy jej aktualność na bardzo wysokim poziomie. 
Nasz katalog polskich firm zawiera miliony sprawdzonych rejestrów, a każdy z nich może okazać się kluczowy dla Twojej kampanii marketingowej. 
Dzięki naszym działaniom masz pewność, że Twoja oferta rozesłana drogą e-mailową lub przekazana drogą telefoniczną trafi do realnych odbiorców.		
<br/><br/>
<b>Oto najważniejsze źródła, które wykorzystujemy do budowy naszych baz:</b>
<br/><br/>
<font color="#00539C">►</font> CEIDG - Centralna Ewidencja i Informacja o Działalności Gospodarczej, czyli publiczna ewidencja firm prowadzonych w formie indywidualnej działalności gospodarczej obsługiwana przez Ministerstwo Przedsiębiorczości i Technologii.<br/>
<font color="#FFD662">►</font> REGON - Krajowy Rejestr Urzędowy Podmiotów Gospodarki Narodowej, czyli publiczny rejestr prowadzony przez Główny Urząd Statystyczny gromadzący informacje o wszystkich podmiotach uczestniczących w obrocie gospodarczym w Polsce.<br/>
<font color="#00539C">►</font> KRS - Krajowy Rejestr Sądowy, czyli państwowy rejestr prowadzony przez Ministerstwo Sprawiedliwości i poszczególne sądy rejonowe zawierający informacje o podmiotach gospodarczych prowadzonych w kilkunastu wybranych formach prawnych.<br/>
<font color="#FFD662">►</font> Pozostałe źródła - Dane teleadresowe firm i osób decyzyjnych pozyskiwane z firmowych stron internetowych oraz popularnych serwisów społecznościowych.

                  </div>  
                   </div>
               </div>

    </>);
}

export default Oferta;