import {Helmet} from "react-helmet";

function Onas() {

    return (<>
    <Helmet>
        <title>O Nas - BazaMaili</title>
        <meta name="description" content="Odkryj przydatne funkcje i zastosowania naszego serwisu, dowiedz się jak możemy Ci pomóc w przeprowadzeniu kampanii marketingowej." />
    </Helmet>
             <div className="container">
                    
                    <br/><br/>   
                       <h3><b><font color="#00539C">O Nas</font></b></h3>
                   
                   <div className="row">
                   <div className="col-md-12">					
                           
                   <br/>
           Zachęcamy do skorzystania z naszego generatora branżowych baz danych firm, dzięki któremu dotrzesz ze swoją ofertą do milionów podmiotów gospodarczych działających w całej Polsce.
           Prowadzisz kampanie mailingowe lub SMS, których efektem jest niska konwersja, a co za tym idzie nie przynoszą satysfakcjonujących zysków dla Twojego biznesu? BazaMaili otworzy przed Tobą nowe możliwości dzięki bazom danych B2B dostosowanym do Twoich potrzeb.
           Nasze narzędzie to doskonałe rozwiązanie dla wszystkich, którzy planują rozpocząć kampanię marketingową opartą o rozsyłanie maili, wiadomości sms, połączenia telefoniczne, a nawet docieranie do potencjalnych klientów drogą pocztową.
           Oferujemy aktualne bazy danych firm z całego kraju zawierające wszelkie dane kontaktowe oraz adresowe, które umożliwią Ci przeprowadzenie skutecznej kampanii marketingowej.
           <br/><br/>
           <img style={{float: 'right', maxWidth: '100%', marginLeft: '15px', marginBottom: '15px'}} src="img/baza mailingowa.png" />

           <b>Nasze narzędzie to idealne rozwiązanie dla tych, którzy planują:</b>
           <br/><br/>
<font color="#00539C">►</font> kampanię e-mail marketingową<br/>
<font color="#FFD662">►</font> kampanię direct mailingową<br/>
<font color="#00539C">►</font> kampanię telemarketingową<br/>
<font color="#FFD662">►</font> wysyłkę reklam drogą pocztową<br/>
<br/>

           Jesteśmy w posiadaniu danych o indywidualnych przedsiębiorcach, spółkach, organizacjach i instytucjach publicznych. 
           Sortowanie i filtrowanie danych umożliwi Ci wygenerowanie bazy idealnie dostosowanej do Twoich potrzeb.
Oferujemy najniższe na rynku ceny za pojedynczy wpis, dzięki czemu nawet przy zamawianiu dużych baz koszty nie są wygórowane.			
           <br/><br/>
           
           Dodatkowo podczas zakupów sam precyzyjnie wybierasz wielkość bazy na jaką pozwala Ci stan Twojego portfela.
           Dzięki naszym usługom zaoszczędzisz sporo pieniędzy, ponieważ nie zamawiasz ogólnej bazy wszystkich firm czy osób decyzyjnych, lecz wyłącznie firmy z wybranej przez Ciebie branży, miasta, województwa lub z określoną datą rozpoczęcia działalności.
           
           
           
           
           <div className="clearfix"> </div>
<br/>
<div className="row">
<div className="col-md-6">
<b>Główne źródła danych o firmach i osobach decyzyjnych, które wykorzystujemy do budowy naszych baz:</b>
<br/><br/>
<font color="#00539C">►</font> Centralna Ewidencja i Informacja o Działalności Gospodarczej<br/>
<font color="#FFD662">►</font> Krajowy Rejestr Urzędowy Podmiotów Gospodarki Narodowej<br/>	
<font color="#00539C">►</font> Krajowy Rejestr Sądowy<br/>
<font color="#FFD662">►</font> Wizytówki firm, portale społecznościowe<br/>
</div>
<div className="col-md-6">
<b>Oto niektóre dane zawarte w naszej bazie uznawane za kluczowe dla gromadzenia listy potencjalnych klientów, działań marketingowych oraz kampanii reklamowych:</b>
<br/><br/>
<font color="#00539C">►</font> dane adresowe firm i osób decyzyjnych<br/>
<font color="#FFD662">►</font> adresy email i numery telefonu<br/>	
<font color="#00539C">►</font> klasyfikacja działalności według kodów pkd<br/>
</div>
</div>
<div className="clearfix"> </div>
<br/>

   
           Dzięki nam zaczniesz przeprowadzać kampanie marketingowe wyłącznie w oparciu o wartościowe kontakty. Pamiętaj o tym, że zakupiona u nas mniejsza baza danych, ale zawierająca bardziej wartościowe leady, osiągnie zdecydowanie lepsze wyniki w kampaniach.
           Oprócz bazy adresów email wraz ze szczegółowymi danymi firm oferujemy również numery telefonu firm i osób decyzyjnych. Kampanie SMS to doskonały sposób na zdobywanie nowych klientów i utrzymywanie z nimi dobrych relacji. Poinformuj konsumentów o zniżkach, ofertach specjalnych, przypomnij im o wizycie lub podziękuj za zakupy.
           Dostarczymy Ci jakościowe kontakty sprzedażowe, aby Twój biznes mógł stale rosnąć! Mamy spore doświadczenie w kolekcjonowaniu i filtrowaniu danych firm zarejestrowanych w CEIDG, KRS, REGON i nie tylko.
           Oferowane przez nas dane mogą być wykorzystywane w dowolnych celach nienaruszających polskiego prawa.
       
           
           
<br/><br/><img style={{float: 'left', maxWidth: '100%', marginLeft: '15px', marginBottom: '15px'}}  src="img/lista firm ceidg.png"/>			
           
           
           Po wybraniu parametrów i wskazaniu jakie konkretnie dane Cię interesują, ostatnim etapem składania zamówienia jest dokonanie płatności online za pośrednictwem serwisu hotpay. Zapewnia on bezpieczeństwo, wygodę i szybki transfer pieniędzy. Dzięki temu niemal od razu zajmiemy się realizacją Twojego zlecenia i dostarczymy Ci zamówiony plik z bazą tak szybko jak to tylko możliwe.

           <br/><br/>			
           <b>Oto co wyróżnia nasze usługi na tle konkurencji:</b>			
                       <br/><br/>
<font color="#00539C">►</font> precyzyjne filtrowanie bazy, wybór kryteriów<br/>
<font color="#FFD662">►</font> najniższe ceny na rynku<br/>
<font color="#00539C">►</font> codzienne aktualizacje, podział na branże<br/>
<font color="#FFD662">►</font> możliwość otrzymania zniżki, promocje, rabaty<br/>
<br/>
       
           Marketingowe bazy danych to uporządkowane zbiory informacji o potencjalnych klientach, które pozwalają na lepsze zrozumienie, zaspokajanie oraz kreowanie potrzeb i oczekiwań odbiorców. Posłużą Ci one do sondowania popytu i prowadzenia skutecznych akcji sprzedażowych oraz reklamowych.
       
           
           
           <div className="clearfix"> </div>
<br/>			
   
Dla Twojej wygody nasze listy kontaktowe udostępniamy w postaci pakietu informacji podstawowych, do których każdy klient może dobrać interesujące go informacje dodatkowe. 
W ramach naszych usług sprzedajemy bazy adresów e-mail do firm z siedzibą w Polsce, uzupełnione o informacje takie jak numery telefonów, adresy stron www, dokładną lokalizacja oraz profil działalności.
Aktualizacja bazy klientów stanowi podstawę skutecznej i efektywnej komunikacji rynkowej firmy. Branżowe listy mailowe i teleadresowe pozwalają na nieustanne poszerzanie grupy odbiorców i docieranie z ofertą wyłącznie do tych klientów, którzy faktycznie potrzebują naszych towarów i usług. 
Zapobiegamy tym samym marnowaniu budżetu na nieskuteczne akcje marketingowe. Od chwili zakupu bazy w naszym serwisie dzieli Cię już tylko krok do stworzenia długotrwałych relacji z klientem.		
<br/><br/>			
<b>Dlaczego warto skorzystać z naszej oferty:</b>
           <br/><br/>
<font color="#00539C">►</font> dzięki filtrowaniu, sortowaniu oraz segregacji danych tworzymy branżowe bazy firm, które oprócz podstawowych danych zawierają również adresy email oraz numery telefonu, co czyni je podstawą kampanii marketingowych<br/>
<font color="#FFD662">►</font> zaoszczędzisz pieniądze kupując jedynie wycinek całej bazy zawierający firmy ściśle powiązane z Twoim biznesem, gdyż to właśnie ich właściciele będą faktycznie zainteresowani ofertą, którą im dostarczysz<br/>
<font color="#00539C">►</font> katalog zawiera miliony firm, spółek i instytucji z całego kraju, więc jeśli zdecydujesz się na zakup całej bazy, będziesz w stanie dotrzeć ze swoją reklamą do wszystkich podmiotów gospodarczych w Polsce<br/>
<font color="#FFD662">►</font> codzienne aktualizacje, wysoka jakość danych, intuicyjny interfejs oraz wygodny system płatności sprawiają, że korzystanie z naszego serwisu jest bezpieczne, łatwe i przyjemne<br/>
           
           
           
           
       
           
            
           
                   
                   </div>  
                   </div>
               </div>
    </>);
}

export default Onas;