import PageContent from './PageContent';
import {
    Link, useLocation
} from "react-router-dom";
import { useState, useEffect } from 'react';
import FakeA from './FakeA';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import BazaMailiAPI from '../services/BazaMailiAPI';

let bazaMailiApi = BazaMailiAPI.getInstance();

const links = [
    {link: '/', classes: 'mybutton nav-item nav-link', text: 'Główna'},
    {link: '/onas', classes: 'mybutton nav-item nav-link', text: 'O Nas'},
    {link: '/faq', classes: 'mybutton nav-item nav-link', text: 'FAQ'},
    {link: '/katalog', classes: 'mybutton nav-item nav-link', text: 'Katalog'},
    {link: '/regulamin', classes: 'mybutton nav-item nav-link', text: 'Regulamin'},
    {link: '/prywatnosc', classes: 'mybutton nav-item nav-link', text: 'Prywatność'},
    {link: '/kontakt', classes: 'mybutton nav-item nav-link', text: 'Kontakt'},
    {link: '/cennik', classes: 'btn', text: 'CENNIK'},
];

function MainPage() {
    const location = useLocation();
    const [cnt, setCnt] = useState(0);

    useEffect(()=> {
        //console.log(location);
        bazaMailiApi.registryCount().then((cnt)=>{
            if (cnt.regCount !== 0) {
                setCnt(cnt.regCount);
            }
        });
    }, []);

    let d = new Date();
    d.setUTCHours(0,0,0,0);
    d.setDate(d.getDate()-1);

    return (<>
   <div className="wrapper">
            <div className="header">
                <div className="container-fluid">
                  <div className="header-top row align-items-center">
                        <div className="col-lg-3">
                            <div className="brand">
                                <FakeA href="/"><b>Baza<font color="#FFD662">Maili</font>.pl</b></FakeA>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="topbar">
                                
                                <div className="topbar-col">
								<small>
								{cnt !== 0 && <><font color="#fff">Ilość firm w bazie:</font> <font color="#FFD662">{cnt}</font></>}&nbsp;&nbsp;
								<font color="#fff">Ostatnia aktualizacja:</font> <font color="#FFD662">{d.getDate()}-{('0'+(d.getMonth()+1)).substr(-2)}-{d.getFullYear()}</font>&nbsp;&nbsp;
                                    <font color="#FFD662"><i className="fa fa-envelope"></i></font> <font color="#fff">bazamailipl@gmail.com</font>
                                </small>
								</div>
                                
                            </div>

{/* 
                            <div className="navbar navbar-expand-lg bg-light navbar-light">
                                <a href="#" className="navbar-brand">MENU</a>
                                <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                                    <span className="navbar-toggler-icon"></span>
                                </button>

                                <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                                    <div className="navbar-nav ml-auto">
                                        {links.map((elem) => {
                                            let cl = elem.classes;

                                            if (location.pathname === elem.link && elem.link !== '/cennik') {
                                                cl += ' active';
                                            }

                                            return <Link to={elem.link} className={cl}>{elem.text}</Link>;
                                        })}
                                        
                                    </div>
                                </div>
                            </div> */}


                            <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
                            <Navbar.Brand href="#home">MENU</Navbar.Brand>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className="ml-auto">

                                {links.map((elem, id) => {
                                            let cl = elem.classes;

                                            if (location.pathname === elem.link && elem.link !== '/cennik') {
                                                cl += ' active';
                                            }

                                            let st = {};
                                            if (elem.link === '/cennik') {
                                                st = {backgroundColor: 'white',
                                                borderRadius: '5px', color: 'blue'};
                                            }

                                            //return <Link to={elem.link} className={cl}>{elem.text}</Link>;
                                            return <Nav.Link key={id} as={Link} to={elem.link} 
                                                style={st}
                                                > {elem.text}</Nav.Link>
                                    })}
                                    

                                </Nav>
                               
                            </Navbar.Collapse>
                            </Navbar>


                        </div>
                    </div>
                </div>
            </div>
            
            
            
            <PageContent/>
            


            <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-3">
                            <div className="footer-contact">
                                 <FakeA href="/"><h2><b><font color="#fff">Baza</font><font color="#FFD662">Maili</font><font color="#fff">.pl</font></b></h2></FakeA>
                               
								<p>
								Największy w Polsce dowstawca baz danych firm, spółek i instytucji zarejestrowanych w CEIDG, KRS I REGON.
								Branżowe listy adresów email osób decyzyjnych, zaktualizowane bazy numerów telefonu oraz szczegółowe dane o firmach i ich właścicielach.
								</p>                                
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="footer-link">
                                <h2>OFERTA</h2>
                                - Bazy podzielone na branże<br/>								
                                - Szczegółowe dane o firmach<br/>
								- Precyzyjne filtrowanie<br/>
								- Listy maili nowych firm<br/>
								- Wybór własnych kryteriów<br/>								
								- Listy numerów telefonu<br/>
								- Prosta obsługa i niskie ceny
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="footer-link">
                                <h2>ZALETY</h2>
                                - Codzienne aktualizacje<br/>
								- Wysoka jakość danych<br/>
								- Intuicyjny interfejs<br/>
								- Wygodne zakupy online<br/>
								- Zniżki oraz bonusy<br/>								
								- Szybka realizacja zamówienia<br/>
								- Stały kontakt z klientem
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="footer-contact">
                                <h2>KONTAKT</h2>
                                                               
								Wszelkie zapytania dotyczące złożonego lub planowanego zamówienia, propozycje, prośby o zniżkę czy zgłoszenia błędów prosimy kierować na nasz kontaktowy adres email bazamailipl@gmail.com
                                                                
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="container copyright">
                    <div className="row">
                        <div className="col-md-6">
                            <p><FakeA href="/">BazaMaili.pl</FakeA> &copy; Wszystkie prawa zastrzeżone.</p>
                        </div>
                        <div className="col-md-6">
                            <p>Dotrzyj ze swoją ofertą do milionów firm z całej Polski.</p>
                        </div>
                    </div>
                </div>
            </div>
            

        </div>


        </>
    );

}

export default MainPage;