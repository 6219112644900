import {Helmet} from "react-helmet";
import {useHistory, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import FileUpload from "./FileUpload";
import {Parser} from "react-tiny-bbcode";

import BazaMailiAPI from '../services/BazaMailiAPI';
let bazaMailiApi = BazaMailiAPI.getInstance();

function EditArticleTypeTwo() {
    let history = useHistory();
    let params = useParams();
    let [article, setArticle] = useState({
        title: '', content: '', title2: '', pkd: '',
        counts: ['', '', '', '', '']
    });

    let {text} =  params;

    useEffect(()=>{
        if (text !== undefined) {
            console.log("text query search = " + text);

            let fetchData = async () => {
                let article = await bazaMailiApi.getArticle2(text);
                setArticle(article);
            };

            fetchData();
        }

        if (text === undefined) {
            // window.ostatnioWybranePKD

            let newArticle = article;
            
            if (window.ostatnioWybranePKD) {
                newArticle = {...newArticle, pkd: window.ostatnioWybranePKD.symbol + ' ' + window.ostatnioWybranePKD.nazwa };
            }

            if (window.iloscWynikow) {

                let newCounts = [window.iloscWynikow.total, window.iloscWynikow.none, window.iloscWynikow.contactEmail, window.iloscWynikow.phoneNumber, window.iloscWynikow.both];
                newArticle = {...newArticle, counts: newCounts};
            }

            if (window.lastSearchCriteria ) {
                newArticle = {...newArticle, searchCriteria: JSON.stringify(window.lastSearchCriteria) };
            }

            setArticle(newArticle);
        }
    }, [text]);

    function uploadDone(data) {
        setArticle({...article, content: article.content += `[img]${data.data.path}[/img]\n` });
    }

    async function saveArticle() {


        if (article.title.trim() === '' || article.title2.trim() === '' ||
        article.content.trim() === '' ||
        article.counts[0].trim === '' ||
        article.counts[1].trim === '' ||
        article.counts[2].trim === '' ||
        article.counts[3].trim === '' ||
        article.counts[4].trim === '' 
        
         ) {
            alert('Wszystkie pola są wymagane!');
            return;
        }
        
        let res = await bazaMailiApi.saveArticle2(article);
        alert('ok!');
        history.push('/edit_article2/'+res.urlName);
    }

    return (<>
            <Helmet>
                <title>Edycja artykułu - BazaMaili</title>
                {/* <meta name="description" content={props.article.content.substr(0, 100)} /> */}
            </Helmet>
            
            <div className="container" style={{paddingTop: '20px'}}>
                    
            
                   <div className="row">
                        <div className="col-lg-6">				
                            {article._id === undefined && <h2>Nowy artykuł typu drugiego</h2>}
                            <p> <button onClick={(evt)=> { history.push('/panel');} } >powrót do panelu</button> &nbsp; <button onClick={(evt)=>{ saveArticle();} } >zapisz</button>  </p>
                    
                            Tytuł:
                            <input type="text" style={{width: '100%'}}
                                onChange={ (evt)=> { setArticle({...article, title: evt.target.value}); } }
                                value={article.title} />

                            PKD:
                            <input disabled type="text" style={{width: '100%'}}
                                onChange={ (evt)=> { setArticle({...article, pkd: evt.target.value}); } }
                                value={article.pkd} />

                            Łączna ilość:
                            <input disabled type="text" style={{width: '100%'}}
                                onChange={ (evt)=> { let newCounts = article.counts; newCounts[0]=evt.target.value; setArticle({...article, counts: newCounts}); } }
                                value={article.counts[0]} />

                            Podstawowe dane:
                            <input disabled type="text" style={{width: '100%'}}
                                onChange={ (evt)=> { let newCounts = article.counts; newCounts[1]=evt.target.value; setArticle({...article, counts: newCounts}); } }
                                value={article.counts[1]} />

                            Z adresem e-mail:
                            <input disabled type="text" style={{width: '100%'}}
                                onChange={ (evt)=> { let newCounts = article.counts; newCounts[2]=evt.target.value; setArticle({...article, counts: newCounts}); } }
                                value={article.counts[2]} />

                            Z numerem telefonu:
                            <input disabled type="text" style={{width: '100%'}}
                                onChange={ (evt)=> { let newCounts = article.counts; newCounts[3]=evt.target.value; setArticle({...article, counts: newCounts}); } }
                                value={article.counts[3]} />

                            E-mail + numer telefonu:
                            <input disabled type="text" style={{width: '100%'}}
                                onChange={ (evt)=> { let newCounts = article.counts; newCounts[4]=evt.target.value; setArticle({...article, counts: newCounts}); } }
                                value={article.counts[4]} />

                            Tytuł2:
                            <input type="text" style={{width: '100%'}}
                                onChange={ (evt)=> { setArticle({...article, title2: evt.target.value}); } }
                                value={article.title2} />

                            Opis:
                            <div style={{width: '100%', height: '200px'}}>
                                <textarea style={{width: '100%', height: '200px'}}
                                    value = {article.content}
                                onChange={ (evt)=> {setArticle({...article, content: evt.target.value}); } }
                            
                                />
                            </div>

                                <br/>
                            <p> <button onClick={(evt)=> {history.push('/panel');} } >powrót do panelu</button> &nbsp; <button onClick={(evt)=>{ saveArticle();} } >zapisz</button>  </p>

                                {/* <FileUpload uploadDone={uploadDone}/> */}

                        </div>

                   
                        <div className="col-lg-6">	
                        <br/><br/>   
                        <h3><b><font color="#00539C">{article.title}</font></b></h3>
                    <br/>         
                            
                        Zamów bazę z aktualnymi danymi polskich przedsiębiorstw i instytucji zarejestrowanych w CEIDG, REGON i KRS, których kod głównej działalności według Polskiej Klasyfikacji Działalności 2007 to {article.pkd}.
<br/><br/>
<div style={{overflowX:'auto'}}>
                            <table className="table table-bordered">
                                <thead style={{backgroundColor: "#FBFBFB"}}>
                                    <tr>
                                        <th><center><font color="#666666">Liczba rekordów</font></center></th>
										<th><center><font color="#666666">Z podstawowymi danymi</font></center></th>
                                        <th><center><font color="#666666">Z adresem email</font></center></th>
                                        <th><center><font color="#666666">Z numerem telefonu</font></center></th>
										<th><center><font color="#666666">Email + numer telefonu</font></center></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><center><b><font color="#00539C">{article.counts[0]}</font></b></center></td>
                                        <td><center><b><font color="#00539C">{article.counts[1]}</font></b></center></td>
                                        <td><center><b><font color="#00539C">{article.counts[2]}</font></b></center></td>
										<td><center><b><font color="#00539C">{article.counts[3]}</font></b></center></td>
                                        <td><center><b><font color="#00539C">{article.counts[4]}</font></b></center></td>
                                    </tr>
                                    
                                </tbody>
								<tbody>
                                    <tr>
                                        <td style={{backgroundColor: "#FBFBFB"}}><center><b><font color="#666666">Cena za wpis</font></b></center></td>
                                        <td><center><b><font color="#FFD662">0,01 zł</font></b></center></td>
                                        <td><center><b><font color="#FFD662">0,03 zł</font></b></center></td>
										<td><center><b><font color="#FFD662">0,03 zł</font></b></center></td>
                                        <td><center><b><font color="#FFD662">0,05 zł</font></b></center></td>
                                    </tr>
                                    
                                </tbody>
                            </table>
</div>
Prezentowana {article.title2} zawiera dane {article.counts[0]} podmiotów gospodarczych.
W powyższej tabeli prezentujemy liczbę uwzględnionych w bazie rekordów zawierających poszczególne informacje.
Pamiętaj, że podczas zamawiania bazy sam decydujesz, czy chcesz kupić wyłącznie listę firm posiadających adresy mailowe lub dane teleadresowe, czy tylko podmioty bez danych kontaktowych.	
<div className="about">
<div className="about-text">
<br/>
<center>
<a className="btn">ZAMÓW BAZĘ</a>
</center>
<br/>
</div>
</div>	
Jeżeli zdecydujesz się na zakup danych z tej bazy, kliknij przycisk "ZAMÓW BAZĘ", który uruchomi proces generowania danych. 
Poczekaj chwilę aż pokażą się wyniki, a następnie wybierz typ danych, jakie Cię interesują i potwierdź złożenie zamówienia.
Ostatnim etapem będzie dokonanie płatności online za pośrednictwem serwisu Dotpay. 
<br/><br/>
	<ul className="list-group">
         <li style={{backgroundColor: "#F7F7F7"}} className="list-group-item"><b>Źródło danych:</b> CEIDG, REGON, KRS, pozostałe</li>
         <li className="list-group-item"><b>Aktualność danych:</b> powyżej 95%</li>
		 <li style={{backgroundColor: "#F7F7F7"}} className="list-group-item"><b>Format plików:</b> XLSX</li>
		 <li className="list-group-item"><b>Formy płatności:</b> Dotpay, karta płatnicza, przelew bankowy</li>
		 <li style={{backgroundColor: "#F7F7F7"}} className="list-group-item"><b>Czas realizacji:</b> do 24 godzin</li>
    </ul>
<br/>
Oferowana baza zawiera dane firm, spółek i instytucji z całej Polski. Niektóre dane zaprezentowane w tabeli na górze strony	mogą być nieaktualne, gdyż prawdopodobnie od czasu opublikowania tego artykułu pojawiło się wiele nowych podmiotów.
							
<br/><br/>
<center>
<h4><b><font color="#00539C">Opis</font></b></h4>
</center>
{article.content}
<br/><br/>                                

                            

                
                        </div> 
                    </div>
                   
            </div>
    </>);
}

export default EditArticleTypeTwo;