import { useState } from 'react';
import {Helmet} from 'react-helmet';

import { Collapse, Button } from 'react-bootstrap';

const data = [
['Jak kupić bazę?', `Użyj generatora znajdującego się na samej górze strony głównej. Wybierz interesującą Cię branżę, lokalizację, status, datę rozpoczęcia działalności oraz ilość firm jaką planujesz zakupić i zatwierdź klikając przycisk WYGENERUJ. Następnie poczekaj aż wyświetlą się wyniki, wybierz rodzaj danych i przejdź do procesu płatności. Zamawianie baz jest bardzo proste i odbywa się za pomocą generatora online.`],
['Ile kosztuje baza?', `Cena bazy firm jest zawsze ustalana indywidualnie na podstawie liczby wpisów oraz rodzaju danych, które wybierzesz podczas jej generowania. Koszt pojedyńczego wpisu z adresem email lub numerem telefonu to 0,03zł, a za wpis zawierający podstawowe dane zapłacisz 0,01zł. Jeśli wpis zawiera zarówno adres email jak i numer telefonu, jego cena wynosi 0,05zł.`],
['Jak opłacić zamówienie?', `Po wybraniu parametrów i wygenerowaniu bazy danych firm, ostatnim etapem składania zamówienia jest dokonanie płatności za pośrednictwem systemu hotpay. Gwarantuje on bezpieczeństwo, komfort i szybki transfer pieniędzy, dzięki czemu niemal natychmiast będziemy mogli zająć się realizacją Twojego zlecenia.`],
['Kiedy otrzymam bazę?', `Maksymalny czas oczekiwania na zakupioną bazę to 24 godziny, ale zazwyczaj dostarczamy plik z danymi już po kilku godzinach od chwili złożenia zamówienia.`],
['Jak pobrać bazę?', `Bazę dostarczymy Ci w załączniku na adres mailowy, który podałeś podczas finalizowania transakcji w systemie hotpay. Nie musisz rejestrować się na naszej stronie, aby pobrać zamówiony plik.`],
['Jakie dane zawiera baza?', `Po dokonaniu bazy przyślemy Ci plik xlsx zawierający dane firm takie jak imię i nazwisko właściciela, nazwa firmy, NIP, REGON, data rozpoczęcia działalności, kody TERC, SIMC i ULIC, miejscowość, ulica, numer budynku, kod pocztowy, gmina, dzielnica, powiat, województwo, adres email, numer telefonu, adres strony internetowej, numer identyfikacyjny i ID wpisu.`],
['Skąd pobieracie dane?', `Najważniejsze źródła, które wykorzystujemy do budowy naszych baz to CEIDG (Centralna Ewidencja i Informacja o Działalności Gospodarczej), REGON (Krajowy Rejestr Urzędowy Podmiotów Gospodarki Narodowej), KRS (Krajowy Rejestr Sądowy) oraz strony internetowe firm i popularne serwisy społecznościowe.`],
['Jak wykorzystać bazę?',`Zakupione u nas dane przydadzą się każdemu kto planuje rozpocząć kampanię telemarketingową, e-mail marketingową, direct mailingową lub rozesłanie reklamy swojej marki drogą pocztową. Jeśli prowadzisz biznes i chcesz wypromować swój produkt, działania marketingowe na pewno Ci w tym pomogą, a żeby je przeprowadzić potrzebne Ci będą dane kontaktowe jak największej ilości potencjalnych klientów, które możesz zdobyć dzięki naszemu serwisowi.`],
['Jak otrzymać zniżkę?', `Zdajemy sobie sprawę z tego, że duże bazy danych firm zawierające dziesiątki tysięcy wpisów to spory wydatek, dlatego w takich przypadkach zachęcamy Cie do kontaktu z nami jeszcze przed dokonaniem zakupu. Wystarczy że sprecyzujesz jaką bazą jesteś zainteresowany, a my postaramy się przygotować dla Ciebie specjalną ofertę z rabatem.`],
['Dlaczego warto kupić?',`Nasze bazy kontaktów różnią się od typowych baz, które można znaleźć na forach czy aukcjach. Dzięki odpowiedniemu filtrowaniu dostarczymy Ci listę potencjalnych klientów wyłącznie z Twojej branży i według wybranych przez Ciebie kryteriów. Dzięki temu można założyć, że wielu z nich będzie realnie zainteresowanych reklamą Twojego produktu podczas kampanii marketingowej.`],
['Nie otrzymałem bazy, co robić?',`Pamiętaj, że bazę zawsze wysyłamy na adres email, który podałeś podczas realizowania płatności na stronie hotpay, tak więc upewnij się, że sprawdzasz odpowiednią skrzynkę mailową. Następnie zajrzyj do folderu SPAM, ponieważ właśnie tam mogła trafić nasza wiadomość z załączoną bazą. Jeśli minęły 24 godziny od chwili złożenia zamówienia i wciąż nie otrzymałeś bazy, skontaktuj się z nami.`]

];

function FAQ() {

    const [open, setOpen] = useState(false);
    const [opened, setOpened] = useState(0);

    return (<>
        <Helmet>
        <title>FAQ - BazaMaili</title>
        <meta name="description" content="Przeczytaj najczęściej zadawane nam pytania i poznaj odpowiedzi. Dowiedz się jak funkcjonuje nasz serwis i sprawdź wachlarz możliwości." />

        </Helmet>

{/* 
    <Button
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
      >
        click
      </Button>
      <Collapse in={open}>
        <div id="example-collapse-text">
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus
          terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer
          labore wes anderson cred nesciunt sapiente ea proident.
        </div>
      </Collapse> */}


            
            <div className="container">
                
                 <br/><br/>   
                    <h3><b><font color="#00539C">FAQ</font></b></h3>
                
                <div className="row">
                
        <div className="faqs">
            <div className="container">
                <div className="row">
                    
                    <div className="col-md-12">
                        <div id="accordion">


                                {data.map( (elem, id) => {
                                    return <>
                            <div className="card">

                                        <div className="card-header">
                                            <a className='card-link' data-toggle="collapse" aria-expanded={opened === id+1} onClick={() => {if (opened === id+1) setOpened(0); else setOpened(id+1); } } >
                                                <span>{id+1}</span> <b>{elem[0]}</b>
                                            </a>
                                        </div>
                                        <Collapse in={opened === id+1}>
                                        <div className="card-body">

                                            {elem[1]}
                                        </div>

                                        </Collapse>
                                        </div>

                                    </>;

                                }) }
 
                        </div>
                       
                    </div>
                    
                </div>
            </div>
        </div>



                
                </div>
            </div>
        
    
    </>);
}
export default FAQ;