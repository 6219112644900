import { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { Collapse } from 'react-bootstrap';
import FakeA from './FakeA';
import { 
    Link,
    useHistory
} from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from "@material-ui/core/styles";
import ResultCountDialog from './ResultCountDialog';
import ResultDateDialog from './ResultDateDialog';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import BazaMailiAPI from '../services/BazaMailiAPI';
let bazaMailiApi = BazaMailiAPI.getInstance();

const data = [
    ['Jak kupić bazę?', `Użyj generatora znajdującego się na samej górze strony głównej. Wybierz interesującą Cię branżę, lokalizację, status, datę rozpoczęcia działalności oraz ilość firm jaką planujesz zakupić i zatwierdź klikając przycisk WYGENERUJ. Następnie poczekaj aż wyświetlą się wyniki, wybierz rodzaj danych i przejdź do procesu płatności. Zamawianie baz jest bardzo proste i odbywa się za pomocą generatora online.`],
    ['Ile kosztuje baza?', `Cena bazy firm jest zawsze ustalana indywidualnie na podstawie liczby wpisów oraz rodzaju danych, które wybierzesz podczas jej generowania. Koszt pojedyńczego wpisu z adresem email lub numerem telefonu to 0,03zł, a za wpis zawierający podstawowe dane zapłacisz 0,01zł. Jeśli wpis zawiera zarówno adres email jak i numer telefonu, jego cena wynosi 0,05zł.`],
    ['Jak opłacić zamówienie?', `Po wybraniu parametrów i wygenerowaniu bazy danych firm, ostatnim etapem składania zamówienia jest dokonanie płatności za pośrednictwem systemu hotpay. Gwarantuje on bezpieczeństwo, komfort i szybki transfer pieniędzy, dzięki czemu niemal natychmiast będziemy mogli zająć się realizacją Twojego zlecenia.`],
    ['Kiedy otrzymam bazę?', `Maksymalny czas oczekiwania na zakupioną bazę to 24 godziny, ale zazwyczaj dostarczamy plik z danymi już po kilku godzinach od chwili złożenia zamówienia.`],
    ['Jak pobrać bazę?', `Bazę dostarczymy Ci w załączniku na adres mailowy, który podałeś podczas finalizowania transakcji w systemie hotpay. Nie musisz rejestrować się na naszej stronie, aby pobrać zamówiony plik.`],
];

  const CssTextField = withStyles({
    root: {

      '& .MuiInputBase-root': {
        color: 'white',
        fontFamily: "'Montserrat', sans-serif"
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child': {
        paddingLeft: '10px',
        marginBottom: '2px',
        marginTop: '10px'
      },
      '& input': {
        paddingLeft: '10px',
        marginBottom: '2px !important',
        marginTop: '10px'
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child::placeholder': {
        color: 'rgba(255,255,255,1)',
        opacity: 0.75
      },
      '& input::placeholder ': {
        color: 'rgba(255,255,255,1)',
        opacity: 0.75
      },
    },
  })(TextField);

function Home(props) {
    let history = useHistory();

    const [opened, setOpened] = useState(0);
    const [wojewodztwa, setWojewodztwa] = useState([]);
    const [miasta, setMiasta] = useState([]);
    const [pkds, setPkds] = useState([]);

    const [wybraneWojewodztwo, ustawWybraneWojewodztwo] = useState(null);
    const [wybranePKD, ustawWybranePKD] = useState(null);

    const [wybraneMiasto, ustawWybraneMiasto] = useState(null);
    const [articles, setArticles] = useState([]);

    const [randomArticles2, setRandomArticles2] = useState([]);

    useEffect(()=> {

        async function fetchData() {
            ustawWybraneMiasto(null);
            if (wybraneWojewodztwo) {
                let m = await bazaMailiApi.getMiasta(wybraneWojewodztwo.woj);
                setMiasta(m);
                
            } else {
                setMiasta([]);
            }
        }

        fetchData();

        bazaMailiApi.getArticles().then( (articles)=> {
            setArticles(articles);
        });

        bazaMailiApi.getRandomArticles2().then( (articles)=> {
            setRandomArticles2(articles.splice(0, 10));
        });

    },[ wybraneWojewodztwo]);

    useEffect(()=>{

        window.ostatnioWybranePKD = null;
        
        async function fetchData() {
            let pkds = await bazaMailiApi.getPkds();
            setPkds(pkds);

            let w = await bazaMailiApi.getWojewodztwa();
            setWojewodztwa(w);
        }

        fetchData();

    }, []);

    const [open, setOpen] = useState(false);

    const [openDataRozpoczeacia, setOpenDataRozpoczenia] = useState(false);

    const [dbSize, setDbSize] = useState("");
    const [selectedValue, setSelectedValue] = useState(null);
    const [selectedDateValue, setSelectedDateValue] = useState("");
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = (value) => {
        setOpen(false);

        if (value) {
            setSelectedValue(value);
            setDbSize(''+value);
        }
    };

    const generate = () => {
        console.log('generating...');

        let searchCriteria = {
            wojewodztwo: null,
            pkd: null,
            miasto: null,
            date: null,
            rozmiar: null
        };

        if (wybraneWojewodztwo) {
            searchCriteria.wojewodztwo = wybraneWojewodztwo.nazwa;
        }

        if (wybranePKD) {
            searchCriteria.pkd = wybranePKD.symbol.replace(/\./g, '');
        }

        if (wybraneMiasto) {
            searchCriteria.miasto = Number(wybraneMiasto.sym);
        }

        if (selectedDateValue.trim()!== '' && selectedDateValue !== 'bez ograniczeń') {
            searchCriteria.date = selectedDateValue;
        }

        if (dbSize.trim()!== '') {
            searchCriteria.rozmiar = dbSize;
        }

        // console.log(searchCriteria);

        // bazaMailiApi.requestRaport(searchCriteria);

        if (props.onUpdate) {
            props.onUpdate("home", {searchCriteria, action: "generateRaport"});
        }

        history.push('/raport');
    }

    const handleClose2 = (value) => {
        setOpenDataRozpoczenia(false);

        let dates;
        
        if (typeof value === 'object' ) {
            let v = value.map((elem)=> elem.split("T")[0]);
            if (v[0] === v[1])
                dates= v[0];
            else dates= v.join(" - ");
        }
        else dates = value;

        setSelectedDateValue(dates);
        //console.log();
        //setSelectedValue(value);
        //setDbSize(value);
    };


    let nums = [];
    for (let i=0; i<articles.length/2; i++) {
        nums.push(i);
    }

    let tableArticles = <table className="table table-bordered">
                               
    <tbody>

        {nums.map( (num)=> {

            let art1 = articles[num*2];
            let art2 = null;

            if (num*2+1 < articles.length) {
                art2 = articles[num*2+1];
            }

            return <tr key={num}>
                    <td style={{width: '50%'}}><FakeA href={'/' + art1.urlName}>{art1.title}</FakeA></td>
                    {art2 && <td><FakeA href={'/' + art2.urlName}>{art2.title}</FakeA></td>}
                    {!art2 && <td></td>}
                </tr>
            ;
        })}
    
        </tbody>
    </table>;

    nums = [];
    for (let i=0; i<randomArticles2.length/2; i++) {
        nums.push(i);
    }

    let tableBazy = <table className="table table-bordered">
                               
    <tbody>

        {nums.map( (num)=> {

            let art1 = randomArticles2[num*2];
            let art2 = null;

            if (num*2+1 < randomArticles2.length) {
                art2 = randomArticles2[num*2+1];
            }

            return <tr key={num}>
                    <td style={{width: '50%'}}><FakeA href={'/' + art1.urlName}>{art1.title}</FakeA></td>
                    {art2 && <td><FakeA href={'/' + art2.urlName}>{art2.title}</FakeA></td>}
                    {!art2 && <td></td>}
                </tr>
            ;
        })}
    
        </tbody>
    </table>;

    return (<>
            <Helmet>
                <title>Baza Maili Firm CEIDG KRS REGON i Dane Teleadresowe</title>
                <meta name="description" content="Baza email firm CEIDG KRS REGON, spis numerów telefonu oraz szczegółowe dane. Lista mailingowa i bazy teleadresowe z podziałem na branże." />

            </Helmet>

            <ResultCountDialog selectedValue={selectedValue} open={open} onClose={handleClose} />
            <ResultDateDialog selectedValue={selectedDateValue} open={openDataRozpoczeacia} onClose={handleClose2} />

            <div className="header home" style={{paddingTop: '0px'}}>
                <div className="container-fluid">

                <div className="hero row align-items-center">
					    <div className="col-md-6">
                            <div className="form">
                                
                                {/* <form autocomplete="off"> */}
								{/* <div className="control-group">
                                        <select className="custom-select">
                                            <option selected>Branża</option>
                                            <option value="1">01.11.Z - Uprawa zbóż, roślin strączkowych i roślin oleistych na nasiona, z wyłączeniem ryżu</option>
                                            <option value="2">01.13.Z - Uprawa warzyw, włączając melony oraz uprawa roślin korzeniowych i roślin bulwiastych</option>
                                            <option value="3">01.19.Z - Pozostałe uprawy rolne inne niż wieloletnie</option>
                                        </select>
                                </div> */}


                                <Autocomplete
                                    id="combo-box-demo"
                                    options={pkds}
                                    value={wybranePKD}
                                        onChange={(event, newValue) => {
                                            ustawWybranePKD(newValue);
                                            window.ostatnioWybranePKD = newValue;
                                        }}
                                    noOptionsText={'Brak wyników.'}
                                    getOptionLabel={(option) => option.symbol + ' - ' + option.nazwa}
                                    renderInput={(params) => <CssTextField 
                                      
                                        {...params} 
                                        InputProps={{...params.InputProps, disableUnderline: true}} fullWidth={true} placeholder="Branża "  autoComplete="false" />}
                                />

                                <Autocomplete
                                    noOptionsText={'Brak wyników.'}
                                        value={wybraneWojewodztwo}
                                        onChange={(event, newValue) => {
                                            ustawWybraneWojewodztwo(newValue);
                                        }}

                                    id="combo-box-demo"
                                    options={wojewodztwa}
                                    
                                    getOptionLabel={(option) => option.nazwa}
                                    renderInput={(params) => <CssTextField 
                                      
                                        {...params} 
                                        InputProps={{...params.InputProps, disableUnderline: true}}fullWidth={true} placeholder="Województwo"  autoComplete="false" />}
                                />

                                {miasta.length>0 &&<Autocomplete
                                    noOptionsText={'Brak wyników.'}
                                    value={wybraneMiasto}
                                    onChange={(event, newValue) => {
                                      
                                        ustawWybraneMiasto(newValue);
                                    }}

                                    id="combo-box-demo"
                                    options={miasta}
                                    getOptionLabel={(option) => option.nazwa}
                                    renderInput={(params) => <CssTextField 
                                        {...params} 
                                        InputProps={{...params.InputProps, disableUnderline: true}} fullWidth={true} placeholder="Miejscowość"  autoComplete="false" />}
                                />}

                                <CssTextField                                       
                                        InputProps={{disableUnderline: true}} 
                                        fullWidth={true} 
                                        value = {selectedDateValue}
                                        placeholder="Data rozpoczęcia"  
                                        autoComplete="false" 
                                        onClick={(e)=> { setOpenDataRozpoczenia(true); } }
                                />

                                <CssTextField                                       
                                        InputProps={{disableUnderline: true}} 
                                        fullWidth={true} 
                                        placeholder="Wielkość bazy" 
                                        autoComplete="false" 
                                        value={dbSize}
                                        onClick={(e)=> { setOpen(true); } }
                                />
{/* 									
                                <input className="form-control" type="text" placeholder="Data rozpoczęcia"/>
								<input className="form-control" type="text" placeholder="Wielkość bazy"/> */}
                                <button style={{marginTop: '10px'}} onClick={(e)=>{ generate(); }} className="btn btn-block">WYGENERUJ</button>
                                {/* </form> */}
                            </div>
                        </div>
						
                        <div className="col-md-6">
                            <h2><b>Baza Firm</b></h2>
                            <h2><span><b>CEIDG KRS REGON</b></span><br/> <b>Do Pobrania</b></h2>
                            <p>Lista ponad 3 milionów podmiotów gospodarczych z całej Polski, w tym adresy email i numery telefonu</p>
                           
                            <Link to='/oferta' className="btn" >OFERTA</Link>
                            
                        </div>
                        
                </div>

            </div></div>

            
            <div className="about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-6">
                            <div className="about-img">
							
                                <img src="img/bazamaili.jpg"/>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6">
                            <div className="about-text">
                                
                                <p>
<font color="#FFD662">►</font> Największa w sieci baza danych firm i osób decyzyjnych, zawierająca zarówno podstawowe informacje i szczegóły działalności, jak również dane kontaktowe takie jak adresy email oraz numery telefonu, które podsłużą Ci do przeprowadzenia kampanii marketingowej.
Spis firm jest aktualizowany codziennie, proces zakupu bezpieczny i wygodny, a precyzyjne filtrowanie zapewnia najwyższą konwersję email marketingu i telemarketingu dzięki wygenerowaniu firm wyłącznie z Twojej grupy docelowej. 							
                                </p>
                                <p>
<font color="#00539C">►</font> Serwis oferuje możliwość filtrowania według kilku różnych kryteriów, a sam proces generowania bazy jest prosty i intuicyjny. W kilka minut możesz skonfigurować, zamówić i otrzymać bazę z danymi Twoich potencjalnych klientów.
Pobierz adresy mailowe i numery telefonów ze ściśle sprofilowanej bazy firm i generuj cenne leady sprzedażowe. Jako jedni z nielicznych dostawców danych oferujemy dostęp do bazy nowych firm zarejestrowanych w dniu poprzednim w najniższej cenie.
                                </p>
                               
                                <Link to='/cennik' className="btn" >CENNIK</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="service">
                <div className="container">
                    <div className="section-header">                        
                        <h3><b><font color="#00539C">Dane Teleadresowe</font></b></h3>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="service-item">
							<br/>
                                <img src="img/lista_mailingowa.jpg"/>
                                <h3><b>Marketing</b></h3>
                                <p>
                                Idealne narzędzie dla osób planujących kampanię marketingową za pomocą mailingu lub drogą telefoniczną.
                                </p>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="service-item">
							<br/>
                                <img src="img/baza email firm.jpg"/>
                                <h3><b>Filtrowanie</b></h3>
                                <p>
                               Możliwość filtrowania według różnych kryteriów, aby dostosować bazę do swojej branży.
                                </p>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="service-item">
							<br/>
                                <img src="img/lista_adresow_email.jpg"/>
                                <h3><b>Wygoda</b></h3>
                                <p>
                                Prostota i intuicyjny interfejs gwarantują wygodne, łatwe i bezpieczne korzystanie z serwisu.
                                </p>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="service-item">
							<br/>
                                <img src="img/spis_polskich_firm.jpg"/>
                                <h3><b>Jakość</b></h3>
                                <p>
                                Wysoka jakość wpisów zawierających wszystkie dane niezbędne do dalszego przetwarzania przez klienta.
                                </p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="newsletter">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <h3><b><font color="#fff">Internetowa Baza Firm i Przedsiębiorstw</font></b></h3>
                            <p>
                                BazaMaili.pl to największy w Polsce dostawca danych firm, spółek, działalności i osób z nimi związanych. Kolekcjonujemy dane bezpośrednio z CEIDG, KRS I REGON, a następnie ulegają one sortowaniu i filtrowaniu, by móc dostarczyć klientom obszerne bazy adresów email firm i dane teleadresowe osób decyzyjnych.
								Znajdziesz tu bazy B2B, kontakty biznesowe i nowe firmy w Polsce, nasza działalność wspomaga telemarketing oraz e-mail marketing. Tak działa rozbudowana wyszukiwarka CEIDG KRS REGON NIP online. 
                            </p>
                        </div>
                        
                    </div>
                </div>
            </div>
			
			
            <div className="feature">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="section-header left">
                                <p>Zalety</p>
                                <h3><b><font color="#00539C">Dane Kontaktowe Firm</font></b></h3>
                            </div>
                            <p>
                            Jeśli interesuje Cię największa baza mailingowa oraz teleadresowa w sieci to trafiłeś pod dobry adres. Nasz katalog zawiera miliony polskich firm zarejestrowanych w CEIDG, KRS i REGON. W zamówionej bazie znajdziesz komplet danych każdej firmy, który pomoże Ci przeprowadzić skuteczną kampanię marketingową.
							Dodatkowo oferowana przez nas lista mailingowa i spis kontaktów telefonicznych do firm podlegają filtrowaniu podczas zakupu, tak aby dostosować je do Twoich potrzeb i pomóc w osiągnieciu maksymalnej konwersji kampanii marketingowej. BazaMaili to platforma internetowa umożliwiająca szybkie i w pełni zautomatyzowane zamawianie danych teleadresowych z wybranych branż i regionów. Dostarczamy sprawdzone i skuteczne bazy mailingowe po niewygórowanych cenach.
                            </p>
                           
                            <Link to='/onas' className="btn" >O NAS</Link>
                        </div>
                        <div className="col-md-7">
                            <div className="row align-items-center feature-item">
                                <div className="col-5">
                                    <img src="img/baza-email.jpg"/>
                                </div>
                                <div className="col-7">
                                    <h3>Polskie Firmy</h3>
                                    <p>
                                        Oferujemy marketingowe bazy danych B2B z informacjami o polskich przedsiębiorstwach, organizacjach społecznych, instytucjach publicznych i związanych z nimi osobach decyzyjnych.
                                    </p>
                                </div>
                            </div>
                            <div className="row align-items-center feature-item">
                                <div className="col-5">
                                    <img src="img/numery telefonu firm.jpg"/>
                                </div>
                                <div className="col-7">
                                    <h3>Skuteczność</h3>
                                    <p>
                                        Nasz codziennie aktualizowany spis firm w Polsce umożliwi Ci przeprowadzenie skutecznych kampanii telemarketingowych, e-mail marketingowych oraz direct mailingowych. 
                                    </p>
                                </div>
                            </div>
                            <div className="row align-items-center feature-item">
                                <div className="col-5">
                                    <img src="img/firmy-katalog.jpg"/>
                                </div>
                                <div className="col-7">
                                    <h3>Lista Kontaktów</h3>
                                    <p>
                                        Pobierz numery telefonu firm ceidg oraz adresy mailowe ze ściśle sprofilowanej listy potencjalnych klientów i generuj cenne leady sprzedażowe. Zwiększ konwersję sprzedaży.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            

            <div className="faqs">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-md-7">
                            <div id="accordion">


                            {data.map( (elem, id) => {
                                    return <div className="card" key={id}>

                                        <div className="card-header">
                                            <a className='card-link' data-toggle="collapse" aria-expanded={opened === id+1} onClick={() => {if (opened === id+1) setOpened(0); else setOpened(id+1); } } >
                                                <span>{id+1}</span> <b>{elem[0]}</b>
                                            </a>
                                        </div>
                                        <Collapse in={opened === id+1}>
                                        <div className="card-body">

                                            {elem[1]}
                                        </div>

                                        </Collapse>
                                        </div>

                                    ;

                                }) }
                             

                            </div>
                            <Link to='/faq' className="btn" >FAQ</Link>
                        </div>
						<div className="col-md-5">
                            <div className="section-header left">
                                <p>FAQ</p>
                                <h3><b><font color="#00539C">Często Zadawane Pytania</font></b></h3>
                            </div>
                            <img style={{maxWidth: '100%'}} src="img/baza-maili.jpg"/>
                        </div>
                    </div>
                </div>
            </div>
            

            
            
            <div className="newsletter">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <h3><b><font color="#fff">Spis Działalności w Polsce</font></b></h3>
                            <p>
                            Nasz katalog zawiera miliony polskich firm podzielonych na branże, kody pkd i obszary działania, a każdego dnia pojawiają się nowe wpisy. Dzięki temu masz pewność, że wygenerowana baza adresów email firm oraz baza numerów telefonu firm wraz z pełnymi danymi jest aktualna i zawiera nawet przedsiębiorstwa, które rozpoczęły swoją działalność w dniu poprzednim.
							Podczas zakupu klient może wybrać jakie wyniki go interesują. Dostępne opcje to lista adresów email, lista numerów telefonu oraz katalog firm bez danych kontaktowych.
							  
							</p>
                        </div>
                        
                    </div>
                </div>
            </div>


            <div className="testimonial">
                <div className="container">
                    <div className="section-header">
                        <p>Źródła Informacji</p>
                        <h3><b><font color="#00539C">Ogólnopolska Baza Danych Klientów</font></b></h3>
                    </div>




                    <OwlCarousel center={true} className="testimonials-carousel" responsive={{
                        0: {
                            items: 1,
                        },
                        750: {
                            items: 2,
                        },
                        1000: {
                            items: 3,
                        }
                    }}
                        loop margin={5}>



                        <div className="testimonial-item">
                            <div className="testimonial-img">
                                <img src="img/maile-lista.png"/>
                            </div>
                            <div className="testimonial-content">
							<h3><b>CEIDG</b></h3><br/>
                                <p>
								Centralna Ewidencja i Informacja o Działalności Gospodarczej (CEIDG) jest publiczną ewidencją firm prowadzonych w formie indywidualnej działalności gospodarczej obsługiwaną przez Ministerstwo Rozwoju.
Do rejestru wpisywani są wszyscy przedsiębiorcy będący osobami fizycznymi i wspólnicy w spółkach cywilnych.
                                    Z oficjalnej strony CEIDG pobieramy aktualne adresy e-mail, adresy stron WWW i numery telefonu, a także wszystkie podstawowe informacje o każdej działalności.
                                </p> 
                            </div>
                        </div>
                        <div className="testimonial-item">
                            <div className="testimonial-img">
                                <img src="img/krs.png"/>
                            </div>
                            <div className="testimonial-content">
							<h3><b>KRS</b></h3><br/>
                                <p>
                                    Krajowy Rejestr Sądowy jest państwowym rejestrem prowadzonym przez Ministerstwo Sprawiedliwości i poszczególne sądy rejonowe zawierającym informacje o podmiotach gospodarczych prowadzonych w kilkunastu wybranych formach prawnych.
									Baza KRS dostarcza nam informacji o kluczowych osobach powiązanych z poszczególnymi podmiotami (np. członkach zarządu, wspólnikach w spółkach), a także danych dotyczących skali ich działalności (np. wysokość kapitału zakładowego).
                                </p>
                            </div>
                        </div>
                        <div className="testimonial-item">
                            <div className="testimonial-img">
                                <img src="img/regon.png"/>
                            </div>
                            <div className="testimonial-content">
							<h3><b>REGON</b></h3><br/>
                                <p>
                                    REGON to publiczny rejestr przygotowany przez Główny Urząd Statystyczny (GUS) gromadzący informacje o wszystkich podmiotach uczestniczących w obrocie gospodarczym w naszym kraju.
									Wpisowi do rejestru REGON podlegają osoby prawne (np. spółki handlowe), jednostki nie posiadające osobowości prawnej oraz przedsiębiorcy i rolnicy.
									Baza REGON stanowi podstawę dla opracowań statystycznych przygotowywanych przez GUS, które wykorzystujemy jako źródło informacji o firmach.
                                </p>
                            </div>
                        </div>
                      
                    </OwlCarousel>


					<br/><br/>
<i>Doskonałe narzędzie dla osób zmagających się z niską skutecznością sprzedaży w e-mail marketingu lub telemarketingu. Dzięki serwisowi Bazamaili wygenerujesz bazy adresów mailowych z wybranej przez Ciebie branży, aby zwiększyć konwersję do maksimum i zaoszczędzić pieniądze. Kup jedynie wycinek całej bazy z danymi kontaktowymi ludzi, którzy rzeczywiście będą zainteresowani Twoim produktem.
Zapomnij o obszernych bazach sprzedawanych na forach, aukcjach czy też sklepach online, w których znajdują się dane kontaktowe osób ze wszystkich branż naraz. Korzystanie z nich poskutkuje tym, że większość adresatów będą stanowić ludzie kompletnie niezainteresowani Twoją wiadomością czy reklamą podczas rozsyłki maili i pewnie nawet jej nie odczytają.					 
Oprócz tego, w celu zwiększenia konwersji podczas prowadzenia kompanii marketingowej użytkownik może u nas wybrać 3 opcje podczas zakupu. Sam zdecyduj czy potrzebna Ci baza maili do pobrania, spis numerów telefonu czy same adresy placówek.              
</i>
<br/><br/>
<div className="section-header">                        
                        <h3><b><font color="#00539C">Przykładowe Bazy</font></b></h3>
                    </div>
                                
                {tableBazy}

				</div>
            </div>
            
            
            <div className="call-to-action">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-9">
                            <h3><b><font color="#fff">Aktualna Baza Teleadresowa</font></b></h3>
                            <p>
                               Oferujemy najniższe ceny na rynku, tj. 1 grosz za wpis bez danych kontaktowych i 3 grosze za wpis posiadający adres email lub numer telefonu. W przypadku większych zamówień przewidujemy rabaty, dostarczenie bazy email to kwestia zaledwie kilku godzin, a serwis obsługuje płatności online hotpay.
                            </p>
                        </div>
                        <div className="col-md-3">
   
                            <Link to='/kontakt' className="btn" >KONTAKT</Link>
                        </div>
                    </div>
                </div>
            </div>


            <div className="blog">
                <div className="container">
				
				
				<div className="row">
                        <div className="col-md-12">
                            
				<img style={{float: 'right', maxWidth: '100%'}} src="img/listy email.png"/>			
<font color="#00539C">►</font> Dokonaj zakupu już dziś i sprawdź czym jest nowoczesna baza email firm ceidg krs regon oraz generator numerów telefonu osób decyzyjnych. Dotrzyj do podmiotów powiązanych z Twoją branżą, co w dużym stopniu przyczyni się do Twojego sukcesu w <u>email marketingu</u> czy telemarketingu.
Nasze innowacyjny serwis służący do generowania dopasywanych baz służy użytkownikom od dłuższego czasu i spełnia oczekiwania co do skuteczności działania oraz szybkości dostarczania listy adresów email do klienta.
Dla ludzi skupiających swoje działania marketingowe na rozsyłce maili lub kontakcie telefonicznym nasze narzędzie służy jako generator, który dostarcza "skuteczne" listy mailingowe wzbogacone o wiele cennych informacji o <u>firmach i spółkach</u>. Dlaczego nasz serwis to najlepszy dostarczyciel listy email w sieci? Ponieważ w przeciwieństwie do innych narzędzi bazamaili.pl umożliwia pobranie list adresów podmiotów wyłącznie powiązanych z Twoją branżą, co poskutkuje maksymalnym zainteresowaniem wiadomością, jeśli dobierzesz odpowiedni temat i treść. Co za tym idzie, zwiększysz konwersję i polepszysz skuteczność działań marketingowych, a to zaowocuje wzrostem zarobków.
		
<br/><br/>
<img style={{float: 'left', marginRight: '15px', maxWidth: '100%'}} src="img/dane teleadresowe firm.png"/>
<font color="#FFD662">►</font> Zapewniamy Cię, że nie będzie to kolejna kupiona w sieci lista email, po której odnotujesz słaby odzew i znikome zainteresowanie reklamą, którą rozesłałeś. U nas otrzymasz szczegółową i obszerną listę kontaktów z uprzednio wybranej przez Ciebie kategorii, co sprawi że zdecydowanie większy procent odbiorców będzie <u>realnie zainteresowany kupnem</u> Twojego produktu i osiągniesz lepsze wyniki sprzedaży oraz pozyskasz ruch na stronie.	
Od wielu lat z powodzeniem tworzymy bazy adresów e-mail B2B, a każdy pozyskany kontakt jest wielokrotnie weryfikowany. Lista firm jest aktualizowana codziennie w oparciu o raporty z serwisów REGON, KRS i CEIDG, dzięki temu gwarantujemy jej aktualność na wysokim poziomie.	
W naszych zasobach znajdują się miliony sprawdzonych rejestrów polskich firm. Każdy z nich może okazać się kluczowy dla rozwoju Twojego biznesu. Dzięki naszej pracy <u>masz pewność</u>, że Twoja oferta rozesłana drogą e-mailową trafi do realnych odbiorców.
Obecnie kampanie mailingowe to idealny sposób na promocję biznesu w internecie, a branżowe bazy danych firm pozwalają dodatkowo uściślić grupę odbiorców, której potrzebuje Twoja działalność. Informacje grają dziś decydującą rolę we wszelkich decyzjach biznesowych, szczególnie jeśli w grę wchodzą stali oraz nowi odbiorcy.
                        </div>
                        
                        
                    </div>
				
				<br/>
				
				<div className="section-header">                        
                        <h3><b><font color="#00539C">Pomocne Artykuły</font></b></h3>
                    </div>
				
				    {tableArticles}
                </div>
            </div>
    
    
    </>);
}

export default Home;
