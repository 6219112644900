import { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import BazaMailiAPI from '../services/BazaMailiAPI';
import Helmet from 'react-helmet';
import { useHistory } from 'react-router-dom';
import Paginator from './Paginator';
import FakeA from './FakeA';

let bazaMailiApi = BazaMailiAPI.getInstance();

function Panel() {
    let history = useHistory();
    let [isLogged, setIsLogged] = useState(false);
    let [password, setPassword] = useState("");
    let [login, setLogin] = useState("");
    let [orders, setOrders] = useState([]);
    let [articles, setArticles] = useState([]);
    let [articles2, setArticles2] = useState([]);

    let [ordersPage, setOrdersPage] = useState(1);
    let [articlesPage, setArticlesPage] = useState(1);
    let [articles2Page, setArticles2Page] = useState(1);

    useEffect(()=> {
        const fetchData = async ()=> {
            let isUserLogged = await bazaMailiApi.checkIfUserIsLogged();
            console.log(`isUserLogged: ${isUserLogged}`);

            setIsLogged(isUserLogged);
        }

        fetchData();
    }, []);

    useEffect(()=> {


        if (isLogged) {
            bazaMailiApi.getOrders().then((orders)=>{
                setOrders(orders);
            });

            bazaMailiApi.getArticles().then((articles)=>{
                setArticles(articles);
            });

            bazaMailiApi.getArticles2().then((articles)=>{
                setArticles2(articles);
            });
        }
    }, [isLogged]);

    const loginFnc = async (evt) => {
        // console.log(evt);
        evt.preventDefault();

        if (login.trim() === '' || password.trim() === '') {
            alert('uzupełnij dane logowania!');
            return;
        }

        let result = await bazaMailiApi.loginUser(login, password);
        if (!result.token) {
            alert("Nieprawidłowe hasło lub login!");
        } 


        console.log(result);
        refreshLoginState();
    }

    const refreshLoginState = async () => {
        let isUserLogged = await bazaMailiApi.checkIfUserIsLogged();
        setIsLogged(isUserLogged);    
        console.log(`isUserLogged: ${isUserLogged}`);
    };

    const logout = () => {
        localStorage.removeItem('token');
        history.push('/');
    }

    const deleteArticle = async (id) => {
        if (window.confirm("na pewno usunąć?")) {
            let arts = articles.filter((elem)=> {
                if (elem._id !== id) return true;
                return false;
            });

            await bazaMailiApi.deleteArticle(id);
            setArticles(arts);
        }
    }

    const deleteArticle2 = async (id) => {
        if (window.confirm("na pewno usunąć?")) {
            let arts = articles2.filter((elem)=> {
                if (elem._id !== id) return true;
                return false;
            });

            await bazaMailiApi.deleteArticle2(id);
            setArticles2(arts);
        }
    }

    let fieldStyle = { width: '150px', minWidth: '80px', borderRadius: '10px', marginBottom: '10px' };
    let btnClasses = "btn btn-primary btn-block text-uppercase";

    return (<>
           
            {isLogged === false && <>
            <Helmet>
                <title>Logowanie - BazaMaili</title>
            </Helmet>
            <div style={{margin: '20px'}}>
                <form>
                <table>
                    <thead></thead>
                    <tbody>
                        <tr><td> login </td><td> <input name="login"
                            onChange={(evt) => { setLogin( evt.target.value ) }}
                            value={login} type="text" /></td></tr>
                        <tr><td> hasło </td><td>
                            <input name="password"
                                onChange={(evt) => { setPassword(evt.target.value);  }}
                                value={password} type="password" /></td></tr>

                    </tbody>

                </table>
                
                <br />
                <a><button type="submit" onClick={(evt) => { loginFnc(evt) }} style={fieldStyle} type="submit" className={btnClasses}>Zaloguj</button></a>
                </form>
            </div>
            </>}


            {isLogged === true && <>
            <Helmet>
                <title>Panel zarządzania - BazaMaili</title>
            </Helmet>
            <div style={{margin: '20px'}}>
                <Button variant="contained" onClick={(evt)=> {logout(); } } >Wyloguj</Button>

                <div style={{padding: '20px'}}>
                    <h2>Zamówienia</h2>
                    <span>Legenda ilości wpisów: a - podstawowe dane, b - z adresem email, c - z numerem telefonu, d - oba</span>
                    <table className="blueTable" style={{width: '100%'}}>
                        <thead>
                            <tr>
                                <td>id</td><td>dodano</td>
                                <td>email</td>
                                <td>kwota</td><td>opłacono?</td>
                                <td>ilość wpisów</td>
                                <td>raport</td>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.slice((ordersPage-1)*10, (ordersPage-1)*10+10).map((elem, i)=> {
                                let tdStyle= elem.paid?{backgroundColor: '#aaffaa'}:{backgroundColor: '#ffaaaa'};


                                return <tr key={i}>
                                    <td>{elem._id}</td>
                                    <td>{(new Date(elem.createdAt)).toLocaleString()}</td>
                                    <td>{elem.email}</td>
                                    <td>{elem.price.toFixed(2)} PLN</td>
                                    <td style={tdStyle}>{elem.paid?"TAK":"NIE"}</td>
                                    <td>{JSON.stringify(elem.counts)}</td>
                                    <td><a download  href={`http://vmi706779.contaboserver.net/api/downloadOrder/${localStorage.token}/${elem._id}`} ><img alt='' src='/img/save.png'></img> Pobierz</a></td>
                                    </tr>;
                            })}
                        </tbody>
                    </table>

                    <Paginator 
                        onPageSelect={(page)=> setOrdersPage(page)}
                        currentPage={ordersPage} pageCount={ Math.ceil( orders.length/10 ) }></Paginator>
                    
                </div>


                <div style={{padding: '20px'}}>
                    <h2>Artykuły typ I</h2>
                    <Button style={{margin: '5px'}} variant="contained" onClick={(evt)=> {history.push('/edit_article'); } } >Nowy artykuł</Button>

                    <table className="blueTable" style={{width: '100%'}}>
                        <thead>
                            <tr>
                                <td>dodano</td>
                                <td>tytuł</td>
                                <td>treść</td>
                                <td> </td>
                            </tr>
                        </thead>
                        <tbody>
                            {articles.slice((articlesPage-1)*10, (articlesPage-1)*10+10).map((elem, i)=> {
                                
                                let shorty = elem.content.substr(0, 100);
                                if (elem.content.length>=100) shorty+='...';

                                return <tr key={i}>
                                    <td>{(new Date(elem.createdAt)).toLocaleString()}</td>
                                    <td><FakeA href={'/'+elem.urlName}>{elem.title}</FakeA></td>
                                    <td>{shorty}</td>
                                    <td>
                                        <FakeA href={'/edit_article/'+elem.urlName}> Edytuj </FakeA>
                                        <span className="clickable" onClick={(e)=> { deleteArticle(elem._id) }}> Usuń </span>
                                    </td>
                                    </tr>;
                            })}
                        </tbody>
                    </table>

                    <Paginator 
                        onPageSelect={(page)=> setArticlesPage(page)}
                        currentPage={articlesPage} pageCount={ Math.ceil( articles.length/10 ) }></Paginator>
                    
                </div>


                <div style={{padding: '20px'}}>
                    <h2>Artykuły typ II</h2>
                    {/* <Button style={{margin: '5px'}} variant="contained" onClick={(evt)=> {history.push('/edit_article2'); } } >Nowy artykuł</Button> */}

                    <table className="blueTable" style={{width: '100%'}}>
                        <thead>
                            <tr>
                                <td>dodano</td>
                                <td>tytuł</td>
                                <td>pkd</td>
                                <td> </td>
                            </tr>
                        </thead>
                        <tbody>
                            {articles2.slice((articles2Page-1)*10, (articles2Page-1)*10+10).map((elem, i)=> {
                                
                                let shorty = elem.content.substr(0, 100);
                                if (elem.content.length>=100) shorty+='...';

                                return <tr key={i}>
                                    <td>{(new Date(elem.createdAt)).toLocaleString()}</td>
                                    <td><FakeA href={'/'+elem.urlName}>{elem.title}</FakeA></td>
                                    <td>{elem.pkd}</td>
                                    <td>
                                        <FakeA href={'/edit_article2/'+elem.urlName}> Edytuj </FakeA>
                                        <span className="clickable" onClick={(e)=> { deleteArticle2(elem._id) }}> Usuń </span>
                                    </td>
                                    </tr>;
                            })}
                        </tbody>
                    </table>

                    <Paginator 
                        onPageSelect={(page)=> setArticles2Page(page)}
                        currentPage={articles2Page} pageCount={ Math.ceil( articles2.length/10 ) }></Paginator>
                    
                </div>
            </div>
            </>}
    </>);
}

export default Panel;
