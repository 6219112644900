import {Helmet} from "react-helmet";
import {useState, useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import BazaMailiAPI from '../services/BazaMailiAPI';
import FakeA from './FakeA';
import Paginator from './Paginator';

let bazaMailiApi = BazaMailiAPI.getInstance();

function Katalog() {
    let history = useHistory();
    let params = useParams();

    const [randomArticles2, setRandomArticles2] = useState([]);
    let [articles2, setArticles2] = useState([]);
    let [articles2Page, setArticles2Page] = useState(params.page?params.page:1);

    // let {page} = params.page;

    const setPage = (page) => {
        if (page==1) history.push('/katalog');
        else history.push('/katalog/' + page);
        setArticles2Page(page);
    }

    useEffect(()=> {


        bazaMailiApi.getRandomArticles2().then( (articles)=> {
            setRandomArticles2(articles);
        });

        bazaMailiApi.getArticles2().then((articles)=>{
            setArticles2(articles);
        });

    }, []);

    return (<>
        <Helmet>
            <title>Katalog - BazaMaili</title>
            <meta name="description" content="Katalog baz danych firm, instytucji i osób decyzyjnych. Kup bazę podmiotów z dowolnej branży i dotrzyj do potencjalnych klientów." />

        </Helmet>

        
        <div className="container">
                    
                    <br/><br/>   
                       <h3><b><font color="#00539C">Katalog</font></b></h3>
                   <br/>
                   <div className="row">
                   <div className="col-md-9">	
                   
{/* <div className="about">
<div className="about-text">
<center>

</center>
</div>
</div>
<br/> */}
<br/>



        {articles2.slice((articles2Page-1)*10, (articles2Page-1)*10+10).map((elem, i)=> {
                                
                                let shorty = elem.content.substr(0, 100);
                                if (elem.content.length>=100) shorty+='...';

                                // return <tr key={i}>
                                //     <td>{(new Date(elem.createdAt)).toLocaleString()}</td>
                                //     <td><FakeA href={'/'+elem.urlName}>{elem.title}</FakeA></td>
                                //     <td>{elem.pkd}</td>
                                //     <td>
                                //         <FakeA href={'/edit_article2/'+elem.urlName}> Edytuj </FakeA>
                                //         <span className="clickable" onClick={(e)=> { deleteArticle2(elem._id) }}> Usuń </span>
                                //     </td>
                                //     </tr>;


                                return <>
<FakeA href={"/"+elem.urlName}><h4><b><font color="#00539C">{elem.title}</font></b></h4></FakeA>
Zamów bazę z aktualnymi danymi polskich przedsiębiorstw i instytucji zarejestrowanych w CEIDG, REGON i KRS, których kod głównej działalności według Polskiej Klasyfikacji Działalności 2007 to {elem.pkd}.
<br/>
<p style={{float: "left"}}>Liczba rekordów: <font color="#FFD662"><b>{elem.counts[0]}</b></font></p>
<p style={{float: "right"}}><FakeA href={"/"+elem.urlName}><font color="#FFD662"><b>Zobacz więcej</b></font></FakeA></p>
<br/><br/>
                                </>;
                            })}







<br/>
<div className="about">
<div className="about-text">
<center>
<Paginator 
        prelink="/katalog/"
        onPageSelect={(page)=> setPage(page)}
        currentPage={articles2Page} pageCount={ Math.ceil( articles2.length/10 ) }></Paginator>
</center>
</div>
</div>

<br/>             
                   </div> 
                   <div className="col-md-3">					
                       <h5><b><font color="#00539C">Polecane Bazy</font></b></h5>
                   <br/>	
                 
                   {randomArticles2.map( (elem, idx) => {
                        return <>
                            <font color={idx%2?"#00539C":"#FFD662"}>►</font> <FakeA href={'/'+elem.urlName}>{elem.title}</FakeA><br/><br/>
                        </>
                   })}
                   
                   
                    </div> 



                   
                   </div>
               </div>
           
    </>);
}

export default Katalog;