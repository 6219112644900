class BazaMailiAPI {
    static myInstance = null;
    isLogged = false;
    refreshTimeout = null;

    static getInstance() {
        if (BazaMailiAPI.myInstance === null) {
            BazaMailiAPI.myInstance = new BazaMailiAPI();
        }

        return this.myInstance;
    }

    async checkIfUserIsLogged () {
        console.log(new Date() + ": checkIfUserIsLogged");
        if (localStorage.token) { 
            console.log('we got token in memory. we should validate it');
            let validate = await BazaMailiAPI.myInstance.validateSession(localStorage.token);

            // console.log(validate);

            if (validate.result === 'success') {
                console.log('TOKEN VALID');

                this.refreshTimeout = setInterval( async ()=> {
                    let res = await this.refreshToken();
                    if (res.result === 'newToken') {
                        console.log('using new token ');
                        localStorage.token = res.token;
                    }

                    if (res.error) {
                        clearInterval(this.refreshTimeout);
                    }

                }, 60*1000);

                return true;
            }
            return false;
        } 
        return false;
    }

    async refreshToken() {
        console.log(new Date()+': refreshing token...');
        let res = await fetch(`/api/refresh?token=${localStorage.token}`);
        let json = await res.json();
        return json;
    }

    async loginUser(username, password) {
        let res = await fetch(`/api/login?user=${username}&password=${password}`);
        let json = await res.json();

        if (json.token) {
            localStorage.token = json.token;
        }

        return json;
    }

    async validateSession(token) {
        let res = await fetch(`/api/validate?token=${token}`);
        let json = await res.json();
        return json;
    }

    async registryCount(token) {
        let res = await fetch(`/api/registryCount`);
        let json = await res.json();
        return json;
    }

    async getWojewodztwa(offset, count) {
        // console.log('get games got called');
        let res = await fetch(`/api/wojewodztwa`);
        let json = await res.json();
        return json;
    }

    async getMiasta(woj_id) {
        let res = await fetch (`/api/miasta/${woj_id}`);
        let json = await res.json();
        return json;
    }

    async identify(text) {
        let res = await fetch (`/api/identify/${text}`);
        let json = await res.json();
        return json;
    }

    async getArticle(urlName) {
        let res = await fetch (`/api/get/article/${urlName}`);
        let json = await res.json();
        return json;
    }

    async getArticle2(urlName) {
        let res = await fetch (`/api/get/article2/${urlName}`);
        let json = await res.json();
        return json;
    }

    async makeOrder(body) {
        const response = await fetch(`/api/makeOrder`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json',
             // 'Authorization': 'Bearer '+localStorage.token
            },
            //redirect: 'follow', // manual, *follow, error
            //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(body) // body data type must match "Content-Type" header
        });

        let json = await response.json(); // parses JSON response into native JavaScript objects
        return json;
    }

    async saveArticle(body) {
        const response = await fetch(`/api/save/article`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+localStorage.token
            },
            //redirect: 'follow', // manual, *follow, error
            //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(body) // body data type must match "Content-Type" header
        });

        let json = await response.json(); // parses JSON response into native JavaScript objects
        return json;
    }

    async saveArticle2(body) {
        const response = await fetch(`/api/save/article2`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+localStorage.token
            },
            //redirect: 'follow', // manual, *follow, error
            //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(body) // body data type must match "Content-Type" header
        });

        let json = await response.json(); // parses JSON response into native JavaScript objects
        return json;
    }

    async getPkds(woj_id) {
        let res = await fetch (`/api/pkds`);
        let json = await res.json();
        return json;
    }

    async getRandomArticles(woj_id) {
        let res = await fetch (`/api/get/randomArticles`);
        let json = await res.json();
        return json;
    }

    async getRandomArticles2(woj_id) {
        let res = await fetch (`/api/get/randomArticles2`);
        let json = await res.json();
        return json;
    }

    async deleteArticle(id) {
        let res = await fetch (`/api/delete/article/${id}`);
        let json = await res.json();
        return json;
    }

    async deleteArticle2(id) {
        let res = await fetch (`/api/delete/article2/${id}`);
        let json = await res.json();
        return json;
    }

    async getArticles(woj_id) {
        let res = await fetch (`/api/get/articles`);
        let json = await res.json();
        return json;
    }

    async getArticles2(woj_id) {
        let res = await fetch (`/api/get/articles2`);
        let json = await res.json();
        return json;
    }

    async getOrders(woj_id) {
        let res = await fetch (`/api/orders`);
        let json = await res.json();
        return json;
    }

    async requestRaport(criteria) {
        const response = await fetch(`/api/request_raport`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json',
             // 'Authorization': 'Bearer '+localStorage.token
            },
            //redirect: 'follow', // manual, *follow, error
            //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(criteria) // body data type must match "Content-Type" header
        });

        let json = await response.json(); // parses JSON response into native JavaScript objects
        return json;
    }

}

export default BazaMailiAPI;