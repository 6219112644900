import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import { TextField, Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 450,
    backgroundColor: theme.palette.background.paper,
  },
}));

function ResultCountDialog(props) {
    const { onClose, selectedValue, open } = props;
    const classes = useStyles();

    const [text, setText] = useState("");
  
    const handleClose = () => {
      onClose(selectedValue);
    };
  
    const handleListItemClick = (value) => {
      onClose(value);
    };
  
    return (
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">Wybierz maksymalną ilość wyników</DialogTitle>
 
        <div className={classes.root}>
      <List component="nav" aria-label="main mailbox folders">
        <Divider />

        <ListItem button onClick={(e)=>{ onClose(100); } }>
          <ListItemText primary="100" />
        </ListItem>
        <Divider />

        <ListItem button onClick={(e)=>{ onClose(1000); } }>
          <ListItemText primary="1 000" />
        </ListItem>
        <Divider />

<ListItem button onClick={(e)=>{ onClose(10000); } }>
  <ListItemText primary="10 000" />
</ListItem>

<Divider />

<ListItem button onClick={(e)=>{ onClose(100000); } }>
  <ListItemText primary="100 000" />
</ListItem>
<Divider />

<ListItem button onClick={(e)=>{ onClose(1000000); } }>
  <ListItemText primary="1 000 000" />
</ListItem>
      </List>
      <Divider />


            {/* <Button onClick={(e)=>{onClose(1000);}} variant="contained" color="primary">1 000</Button>
            <Button onClick={(e)=>{onClose(10000);}} variant="contained" color="primary">10 000</Button>
            <Button onClick={(e)=>{onClose(100000);}} variant="contained" color="primary">100 000</Button> */}
    
            <div style={{padding: '5px', textAlign: 'center'}}>
            <TextField fullWidth={true} value={text} onChange={(e)=>{ setText(e.target.value)}} type="number" label="inna ilość wyników"/> 
            <Button style={{margin: '5px'}} onClick={(e)=>{onClose(text);}}  variant="contained" color="secondary">OK</Button>
            </div>

        </div>


     


      </Dialog>
    );
  }

  // ResultCountDialog.propTypes = {
  //   onClose: PropTypes.func.isRequired,
  //   open: PropTypes.bool.isRequired,
  //   selectedValue: PropTypes.string.isRequired,
  // };

  export default ResultCountDialog;