import {Helmet} from "react-helmet";

function Kontakt() {
    return (<>
                    <Helmet>
                    <title>Kontakt - BazaMaili</title>
        <meta name="description" content="Chcesz się z nami skontaktować? Napisz wiadomość na adres email podany na stronie, a my odpowiemy tak szybko jak to możliwe." />

            </Helmet>
            <div className="container">
                
                <br/><br/>     
                    <h3><b><font color="#00539C">Kontakt</font></b></h3>
                
                <div className="row">
                <div className="col-md-12">					
                <img style={{float: "right"}} src="img/kontakt.png"/>
                <br/>
                Kontakt z nami najłatwiej nawiązać drogą mailową, staramy się zawsze odpowiadać na nadesłane wiadomości w ciągu 24 godzin.
                Zanim do nas napiszesz, polecamy Ci przeczytać FAQ. Być może znajdziesz tam odpowiedź na nurtujące Cię pytanie.
                Wszelkie zapytania dotyczące zamówień, propozycje biznesowe, prośby o zniżkę czy zgłoszenia błędów prosimy kierować na adres email podany poniżej.  
                    
                <br/><br/>	
                    Email kontaktowy: <b><font color="#00539C">bazamailipl@gmail.com</font></b>
                <br/><br/>	
                
                Chętnie odpowiemy na wszystkie Twoje pytania związane z działaniem naszego serwisu, opiszemy pełną funkcjonalność oraz możliwości.
                Jeśli wahasz się czy nasze usługi sprostają Twoim oczekiwaniom, skontaktuj się z nami i dowiedz się więcej.
                Znalazłeś błąd na stronie? Chciałbyś złożyć reklamację? A może masz dla nas jakąś propozycję współpracy?				
                Jeśli tak, w swojej wiadomości opisz szczegółowo problem lub ofertę i poczekaj na szybką odpowiedź.
                <br/><br/>
                
                
                
                
                
                
                </div>  
                </div>
            </div>
       
    </>);
}

export default Kontakt;