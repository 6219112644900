import Helmet from 'react-helmet';

function Cennik() {

    return (<>
        <Helmet>
        <title>Cennik - BazaMaili</title>
        <meta name="description" content="Prezentujemy aktualnie obowiązujące ceny za pojedynczy wpis. Przypominamy, że cena zależy od wielkości bazy oraz dobranych kryteriów." />

        </Helmet>
        <div className="container">
                    
                    <br/><br/>   
                       <h3><b><font color="#00539C">Cennik</font></b></h3>
                   
                   <div className="row">
                   <div className="col-md-12">					
                       <img style={{float: 'right'}} src="img/cennik.png"/>	
                   <br/>	
           Cena bazy jest zawsze ustalana indywidualnie w chwili zakupu na podstawie liczby wpisów oraz rodzaju danych, które klient wybrał podczas jej generowania. Poniżej znajdują się ceny za pojedynczy wpis.
       <br/><br/>	
           - cena wpisu z podstawowymi danymi: <b><font color="#00539C">0,01 zł</font></b><br/>	
           - cena wpisu z adresem email: <b><font color="#00539C">0,03 zł</font></b><br/>
           - cena wpisu z numerem telefonu: <b><font color="#00539C">0,03 zł</font></b><br/>
           - cena wpisu z obiema formami kontaktu: <b><font color="#00539C">0,05 zł</font></b><br/>			
       <br/>	
         Informujemy, że niemal każdy wpis zawiera podstawowe dane takie jak imię i nazwisko właściciela firmy, NIP, REGON, nazwę firmy, datę rozpoczęcia działalności, kody TERC, SIMC i ULIC, szczegółowe dane adresowe takie jak gmina, ulica, numer budynku, kod pocztowy, dzielnica, miejscowość, powiat i województwo, a także ID wpisu, numer identyfikacyjny oraz sporadycznie adres strony internetowej.	
         Za pojedynczy wpis zawierający wyżej wymienione dane zapłacisz jedynie 1 grosz, natomiast wpis z danymi kontaktowymi takimi jak adres email lub numer telefonu kosztuje 3 grosze.
        <br/>				
                   
                   </div>  
                   </div>
               </div>

    </>);
}

export default Cennik;